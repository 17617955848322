import React from 'react'
import {Form, Input, DatePicker, Button, Space, Select} from 'antd'
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'

const PolicyForm = (props) => {
	const {
		policyForm,
		insuranceCompanyList,
		onNewInsuranceCompany,
		typeInsuranceList,
		onNewTypeInsurance,
		clientList,
		onNewClient,
		agentList,
		onNewAgent,
		onSubmit,
	} = props

	const dateFormat = 'DD/MM/YYYY'

	const {RangePicker} = DatePicker

	const insuranceCompanySelect = insuranceCompanyList.map((info) => {
		let information = {
			value: info.id,
			label: info.name,
		}
		return information
	})

	const typeInsuranceSelect = typeInsuranceList.map((info) => {
		let information = {
			value: info.id,
			label: info.name,
		}
		return information
	})

	const clientSelect = clientList.map((info) => {
		let information = {
			value: info.id,
			label: info.contact.name + ' ' + info.contact.first_last_name,
		}
		return information
	})

	const agentSelect = agentList.map((info) => {
		let information = {
			value: info.id,
			label: info.contact.name + ' ' + info.contact.first_last_name,
		}
		return information
	})

	const optionSelect = [
		{
			value: 'ACTIVE',
			label: 'Activo',
		},
		{
			value: 'INACTIVE',
			label: 'Inactivo',
		},
	]
	const layout = {
		labelCol: {span: 7},
	}

	return (
		<Form
			{...layout}
			form={policyForm}
			initialValues={{
				status: 'ACTIVE',
			}}
			onFinish={onSubmit}
		>
			<Form.Item
				name="policy_number"
				label="Numero de Póliza"
				rules={[
					{
						required: true,
						message: 'Hace falta el numero de póliza',
					},
				]}
			>
				<Input name="policy_number"></Input>
			</Form.Item>
			<Form.Item name="dates" label="Fecha de Vigencia" rules={[{required:true,message:'Las fechas son requeridas'}]}>
				<RangePicker format={dateFormat} />
			</Form.Item>

			<Form.Item name="status" label="Estatus">
				<Select
					name="status"
					options={optionSelect}
					placeholder="Selecciona uno"
				/>
			</Form.Item>
			<Form.Item
				name="total_payment"
				label="Pago total"
				rules={[
					{
						required: true,
						message: 'Hace falta el pago',
					},
				]}
			>
				<Input name="total_payment" />
			</Form.Item>
			<Form.Item label="Cliente" required={true}>
                <Space>
					<Form.Item name="client" rules={[{required:true,message:'El cliente es requerido'}]}>
						<Select
							name="client"
							options={clientSelect}
							style={{ width: 200 }}
							placeholder="Selecciona un Cliente"
						/>
					</Form.Item>
					<Button
						name="addClients"
						type="link"
						onClick={onNewClient}
					
					>
						Agregar Nuevo Cliente
					</Button>
				</Space>
			</Form.Item>

			<Form.Item label="Compañia Aseguradora" required={true}>
				<Space>
					<Form.Item name="insurance_company" rules={[{required:true, message:'La Aseguradora es requerida'}]} >
						<Select
							options={insuranceCompanySelect}
							style={{ width: 200 }}
							placeholder="Selecciona una Compañia"
						/>
					</Form.Item>
					<Button type="link" onClick={onNewInsuranceCompany}>
						Agregar Compañia
					</Button>
				</Space>
			</Form.Item>

			<Form.Item label="Tipo de Seguro" required={true}>
				<Space>
					<Form.Item name="type_insurance" rules ={[{required: true, message:'El Tipo de seguro es requerido'}]}>
						<Select
							options={typeInsuranceSelect}
							style={{ width: 200 }}
							placeholder="Selecciona un Tipo"
						/>
					</Form.Item>
					<Button type="link" onClick={onNewTypeInsurance}>
						Agregar Tipo de Seguro
					</Button>
				</Space>
			</Form.Item>			
			
			<Form.Item name="agent" label="Agente" required={true} rules={[{required:true,message:'El Agente es requerido'}]}>
				<Select
					options={agentSelect}
					style={{ width: 200 }}
					placeholder="Selecciona un Agente"
				/>
			</Form.Item>

			<Form.Item label="Sub-agente">
				<Space>
					<Form.Item name="sub_agent">
						<Select
							options={agentSelect}
							style={{ width: 200 }}
							placeholder="Selecciona un Sub-agente"
						/>
					</Form.Item>
					<Button type="link" onClick={onNewAgent} block>
						Agregar Un Tipo De Agente
					</Button>
				</Space>

			</Form.Item>

			<Form.List name="receipts" align="center">
				{(fields, {add, remove}) => (
					<>
						{fields.map(({key, name, fieldKey, ...restField}) => (
							<Space
								key={key}
								align="baseline"
								style={{marginTop:'2%'}}
							>
								<Form.Item 
									{...restField}
									name={[name, 'payment_scheduled_date']}
									fieldKey={[
										fieldKey,
										'payment_scheduled_date',
									]}
									rules={[
										{
											required: true,
											message: 'Hace falta la fecha',
										},
									]}
								>
									<DatePicker
										placeholder=" Fecha Pago "
										format={dateFormat}
									/>
								</Form.Item>
								<Form.Item
									{...restField}
									name={[name, 'payment']}
									fieldKey={[fieldKey, 'payment']}
									rules={[
										{
											required: true,
											message: 'Hace falta el pago',
										},
									]}
								>
									<Input placeholder=" Pago " />
								</Form.Item>
								<MinusCircleOutlined
									onClick={() => remove(name)}
								/>
							</Space>
						))}
						<Form.Item align="center">
							<Button
								onClick={() => add()}
								block
								icon={<PlusOutlined />}
							>
								Agregar Recibo
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
			<Form.List name="insureds" >
				{(fields, {add, remove}) => (
					<>
						{fields.map(({key, name, fieldKey, ...restField}) => (
							<Space
								key={key}
								align="baseline"
							>
								<Form.Item 
									{...restField}
									name={[name, 'name']}
									fieldKey={[fieldKey, 'name']}
									rules={[
										{
											required: true,
											message: 'Hace falta el nombre',
										},
									]}
								>
									<Input placeholder="Nombre" />
								</Form.Item>
								<Form.Item 
									{...restField}
									name={[name, 'first_last_name']}
									fieldKey={[fieldKey, 'first_last_name']}
									rules={[
										{
											required: true,
											message: 'Hace falta el apellido',
										},
									]}
								>
									<Input placeholder="Apellido Paterno" />
								</Form.Item>
								<Form.Item
									{...restField}
									name={[name, 'second_last_name']}
									fieldKey={[fieldKey, 'second_last_name']}
									rules={[
										{
											required: true,
											message: 'Hace falta el apellido',
										},
									]}
								>
									<Input placeholder="Apellido Materno" />
								</Form.Item>
								<Form.Item
									{...restField}
									name={[name, 'birthday']}
									fieldKey={[fieldKey, 'birthday']}
									rules={[
										{
											required: true,
											message: 'Hace falta la fecha',
										},
									]}
								>
									<DatePicker
										placeholder=" Fecha de nacimiento "
										format={dateFormat}
									/>
								</Form.Item>
								<MinusCircleOutlined
									onClick={() => remove(name)}
								/>
							</Space>
						))}
						<Form.Item align="center">
							<Button
								onClick={() => add()}
								block
								icon={<PlusOutlined />}
							>
								Agregar asegurado
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
			<br />
			<Button
				style={{marginTop: 20, marginInline: 5, marginLeft: 450}}
				onClick={() => props.onCancel()}
			>
				Cancelar
			</Button>
			<Button type="primary" htmlType="submit">
				Guardar
			</Button>
		</Form>
	)
}

export default PolicyForm
