import {takeLatest, call, put} from 'redux-saga/effects'
import {
	addTypeInsurance,
	getTypeInsurance,
	deleteTypeInsurance,
	getDetailTypeInsurance,
	editTypeInsurance,
} from '../../api/typeInsuranceServices'
import {
	GET_TYPES_INSURANCE_REQUEST,
	GET_TYPES_INSURANCE_SUCCESS,
	GET_TYPES_INSURANCE_ERROR,
	GET_DETAIL_TYPE_INSURANCE_REQUEST,
	GET_DETAIL_TYPE_INSURANCE_SUCCESS,
	GET_DETAIL_TYPE_INSURANCE_ERROR,
	ADD_TYPE_INSURANCE_REQUEST,
	ADD_TYPE_INSURANCE_SUCCESS,
	ADD_TYPE_INSURANCE_ERROR,
	DELETE_TYPE_INSURANCE_REQUEST,
	DELETE_TYPE_INSURANCE_SUCCESS,
	DELETE_TYPE_INSURANCE_ERROR,
	EDIT_TYPE_INSURANCE_REQUEST,
	EDIT_TYPE_INSURANCE_SUCCESS,
	EDIT_TYPE_INSURANCE_ERROR,
} from '../actions/typeInsuranceActions'

export function* addTypeInsuranceWatcher() {
	yield takeLatest(ADD_TYPE_INSURANCE_REQUEST, addTypeInsuranceFlow)
}

export function* addTypeInsuranceFlow(action) {
	try {
		yield call(addTypeInsurance, action.payload)
		yield put({type: ADD_TYPE_INSURANCE_SUCCESS, payload: {}})
	} catch (e) {
		yield put({type: ADD_TYPE_INSURANCE_ERROR, payload: e})
	}
}

export function* addTypeInsuranceSuccessWatcher() {
	yield takeLatest(ADD_TYPE_INSURANCE_SUCCESS, paginationRefresh)
}

export function* getTypeInsuranceWatcher() {
	yield takeLatest(GET_TYPES_INSURANCE_REQUEST, getTypeInsuranceFlow)
}

export function* getTypeInsuranceFlow(action) {
	try {
		const typesInsurances = yield call(
			getTypeInsurance,
			action.payload.page
		)
		yield put({
			type: GET_TYPES_INSURANCE_SUCCESS,
			payload: {
				list: typesInsurances,
				currentPage: action.payload.page,
			},
		})
	} catch (e) {
		yield put({type: GET_TYPES_INSURANCE_ERROR, payload: e})
	}
}

export function* getDetailTypeInsuranceWatcher() {
	yield takeLatest(
		GET_DETAIL_TYPE_INSURANCE_REQUEST,
		getDetailTypeInsuranceFlow
	)
}

export function* getDetailTypeInsuranceFlow(action) {
	try {
		const detailTypeInsurance = yield call(
			getDetailTypeInsurance,
			action.payload
		)
		yield put({
			type: GET_DETAIL_TYPE_INSURANCE_SUCCESS,
			payload: {
				detail: detailTypeInsurance,
			},
		})
	} catch (e) {
		yield put({type: GET_DETAIL_TYPE_INSURANCE_ERROR, payload: e})
	}
}

export function* deleteTypeInsuranceWatcher() {
	yield takeLatest(DELETE_TYPE_INSURANCE_REQUEST, deleteTypeInsuranceFlow)
}

export function* deleteTypeInsuranceFlow(action) {
	try {
		const typesInsurances = yield call(deleteTypeInsurance, action.payload)
		yield put({
			type: DELETE_TYPE_INSURANCE_SUCCESS,
			payload: typesInsurances,
		})
	} catch (e) {
		yield put({type: DELETE_TYPE_INSURANCE_ERROR, payload: e})
	}
}

export function* deleteTypeInsuranceSuccessWatcher() {
	yield takeLatest(DELETE_TYPE_INSURANCE_SUCCESS, paginationRefresh)
}

export function* editTypeInsuranceWatcher() {
	yield takeLatest(EDIT_TYPE_INSURANCE_REQUEST, editTypeInsuranceFlow)
}

export function* editTypeInsuranceFlow(action) {
	try {
		yield call(editTypeInsurance, action.payload)
		yield put({type: EDIT_TYPE_INSURANCE_SUCCESS, payload: {}})
	} catch (e) {
		yield put({type: EDIT_TYPE_INSURANCE_ERROR, payload: e})
	}
}

export function* editTypeInsuranceSuccessWatcher() {
	yield takeLatest(EDIT_TYPE_INSURANCE_SUCCESS, paginationRefresh)
}

export function* paginationRefresh(action) {
	yield put({
		type: GET_TYPES_INSURANCE_REQUEST,
		payload: {page: 1},
	})
}
