import {combineReducers} from 'redux'
import {authUserReducer} from './authUserReducer'
import {typeInsuranceReducer} from './typeInsuranceReducer'
import {rfcReducer} from './rfcReducer'
import {insuranceCompanyReducer} from './insuranceCompanyReducer'
import {agentReducer} from './agentReducer'
import {clientsReducer} from './clientsReducer'
import {policyReducer} from './policyReducer'

export const rootReducer = combineReducers({
	authUserReducer,
	typeInsuranceReducer,
	rfcReducer,
	insuranceCompanyReducer,
	agentReducer,
	clientsReducer,
	policyReducer,
})
