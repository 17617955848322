import React from 'react'
import {Modal, Spin} from 'antd'
import {Formik} from 'formik'
import TypeInsuranceForm from '../forms/TypeInsuranceForm/TypeInsuranceForm'
import TypeInsuranceFormValidation from '../forms/TypeInsuranceForm/TypeInsuranceFormValidation'

const TypeInsuranceModal = (props) => {
	const {
		modalTitle,
		modalVisible,
		modalSpinning,
		modalCancel,
		modalSubmit,
		modalFormikInitialValues,
	} = props

	return (
		<Modal
			title={modalTitle}
			visible={modalVisible}
			onCancel={modalCancel}
			footer={null}
			confirmLoading={true}
			keyboard={true}
			destroyOnClose={true}
		>
			<Spin spinning={modalSpinning}>
				<Formik
					validationSchema={TypeInsuranceFormValidation}
					onSubmit={modalSubmit}
					initialValues={modalFormikInitialValues}
					enableReinitialize={true}
				>
					{() => <TypeInsuranceForm onCancel={modalCancel} />}
				</Formik>
			</Spin>
		</Modal>
	)
}

export default TypeInsuranceModal
