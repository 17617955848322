import React from 'react'
import {Table, Button, Space, Popconfirm} from 'antd'

const EditableTable = (props) => {
    const {
		loadingClients,
		data,
		totalItems,
		onPagination,
		onDelete,
		getDetail,
		currentPage
	} = props
	
	const clientInfo = data.map(({id,rfc,contact}) =>({
		key:id,
		...contact,
		second_last_name: (contact.second_last_name === '')?'No asignado':contact.second_last_name,
		email: (contact.email === '')?'No asignado': contact.email,
		rfc: (rfc === null)?'No asignado':rfc.fiscal_id
	}))

	return (
		<Table 
			loadingClients={loadingClients}
			dataSource={clientInfo}
        	pagination={{
				onChange: (page) => {
					onPagination(page)
				},
				pageSize:process.env.REACT_APP_API_PAGINATION,
				current: currentPage,
				total:totalItems
			}}
		>
			<Table.Column title="Nombre" dataIndex="name"/>
			<Table.Column title="Primer Apellido" dataIndex="first_last_name" />
			<Table.Column title="Segundo Apellido" dataIndex="second_last_name" />
			<Table.Column title="Celular" dataIndex="mobile" />
			<Table.Column title="correo" dataIndex="email" />
			<Table.Column title="RFC" dataIndex="rfc" />
			<Table.Column
				title="Accion"
				key="action"
				align="center"
				render={(text, record) => (
					<Space size="middle">
						<Button 
							type="link" 
							onClick={()=> getDetail(record.key)}
						>
							Editar
						</Button>
						<Popconfirm
							title="Seguro que desea eliminar"
							okText="Si"
							cancelText="No"
							onConfirm={() => {
								onDelete(record.key)
							}}
						>
							<Button type="link">Eliminar</Button>
						</Popconfirm>
					</Space>
				)}
			/>
		</Table>
	)
}

export default EditableTable
