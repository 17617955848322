export const ADD_TYPE_INSURANCE_REQUEST = 'ADD_TYPE_INSURANCE_REQUEST'
export const ADD_TYPE_INSURANCE_SUCCESS = 'ADD_TYPE_INSURANCE_SUCCESS'
export const ADD_TYPE_INSURANCE_ERROR = 'ADD_TYPE_INSURANCE_ERROR'

export const GET_TYPES_INSURANCE_REQUEST = 'GET_TYPES_INSURANCE_REQUEST'
export const GET_TYPES_INSURANCE_SUCCESS = 'GET_TYPES_INSURANCE_SUCCESS'
export const GET_TYPES_INSURANCE_ERROR = 'GET_TYPES_INSURANCE_ERROR'

export const GET_DETAIL_TYPE_INSURANCE_REQUEST =
	'GET_DETAIL_TYPE_INSURANCE_REQUEST'
export const GET_DETAIL_TYPE_INSURANCE_SUCCESS =
	'GET_DETAIL_TYPE_INSURANCE_SUCCESS'
export const GET_DETAIL_TYPE_INSURANCE_ERROR = 'GET_DETAIL_TYPE_INSURANCE_ERROR'

export const DELETE_TYPE_INSURANCE_REQUEST = 'DELETE_TYPE_INSURANCE_REQUEST'
export const DELETE_TYPE_INSURANCE_SUCCESS = 'DELETE_TYPE_INSURANCE_SUCCESS'
export const DELETE_TYPE_INSURANCE_ERROR = 'DELETE_TYPE_INSURANCE_ERROR'

export const EDIT_TYPE_INSURANCE_REQUEST = 'EDIT_TYPE_INSURANCE_REQUEST'
export const EDIT_TYPE_INSURANCE_SUCCESS = 'EDIT_TYPE_INSURANCE_SUCCESS'
export const EDIT_TYPE_INSURANCE_ERROR = 'EDIT_TYPE_INSURANCE_ERROR'

export const addTypeInsurance = (data) => ({
	type: ADD_TYPE_INSURANCE_REQUEST,
	payload: data,
})

export const getTypesInsurance = (data) => ({
	type: GET_TYPES_INSURANCE_REQUEST,
	payload: data,
})

export const getDetailTypesInsurance = (data) => ({
	type: GET_DETAIL_TYPE_INSURANCE_REQUEST,
	payload: data,
})

export const deleteTypeInsurance = (data) => ({
	type: DELETE_TYPE_INSURANCE_REQUEST,
	payload: data,
})

export const editTypeInsurance = (data) => ({
	type: EDIT_TYPE_INSURANCE_REQUEST,
	payload: data,
})
