export const ADD_POLICY_REQUEST = 'ADD_POLICY_REQUEST'
export const ADD_POLICY_SUCCESS = 'ADD_POLICY_SUCCESS'
export const ADD_POLICY_ERROR = 'ADD_POLICY_ERROR'

export const GET_POLICY_REQUEST = 'GET_POLICY_REQUEST'
export const GET_POLICY_SUCCESS = 'GET_POLICY_SUCCESS'
export const GET_POLICY_ERROR = 'GET_POLICY_ERROR'

export const GET_DETAIL_POLICY_REQUEST = 'GET_DETAIL_POLICY_REQUEST'
export const GET_DETAIL_POLICY_SUCCESS = 'GET_DETAIL_POLICY_SUCCESS'
export const GET_DETAIL_POLICY_ERROR = 'GET_DETAIL_POLICY_ERROR'

export const DELETE_POLICY_REQUEST = 'DELETE_POLICY_REQUEST'
export const DELETE_POLICY_SUCCESS = 'DELETE_POLICY_SUCCESS'
export const DELETE_POLICY_ERROR = 'DELETE_POLICY_ERROR'

export const EDIT_POLICY_REQUEST = 'EDIT_POLICY_REQUEST'
export const EDIT_POLICY_SUCCESS = 'EDIT_POLICY_SUCCESS'
export const EDIT_POLICY_ERROR = 'EDIT_POLICY_ERROR'

export const addPolicy = (data) => ({
    type: ADD_POLICY_REQUEST,
    payload: data,
}) 

export const getPolicy = (data) => ({
    type: GET_POLICY_REQUEST,
    payload: data,
}) 

export const getDetailPolicy = (data) => ({
    type: GET_DETAIL_POLICY_REQUEST,
    payload: data,
}) 

export const deletePolicy = (data) => ({
    type: DELETE_POLICY_REQUEST,
    payload: data,
}) 

export const editPolicy = (data) => ({
    type: EDIT_POLICY_REQUEST,
    payload: data,
}) 