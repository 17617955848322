import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, message} from 'antd'
import LayoutInternal from '../../utils/layout/LayoutInternal/LayoutInternal'
import {
	addTypeInsurance,
	getTypesInsurance,
	deleteTypeInsurance,
	getDetailTypesInsurance,
	editTypeInsurance,
} from '../../redux/actions/typeInsuranceActions'
import TypeInsuranceTable from '../../components/tables/TypeInsuranceTable/typeInsuranceTable'
import TypeInsuranceModal from '../../components/modals/typeInsuranceModal'

const TypeInsuranceContainer = () => {
	const dispatch = useDispatch()

	const listTypesInsurances = useSelector(
		(state) => state.typeInsuranceReducer.list.results
	)

	const {
		isLoadingTypeInsuranceList,
		isLoadingAddTypeInsurance,
		isLoadingUpdateTypeInsurance,
		isLoadingFetchTypeInsurance,
		showError,
		showSuccess,
	} = useSelector((state) => state.typeInsuranceReducer)

	const itemsTotalList = useSelector(
		(state) => state.typeInsuranceReducer.list.count
	)

	const currentPage = useSelector(
		(state) => state.typeInsuranceReducer.currentPage
	)

	const detailRecord = useSelector(
		(state) => state.typeInsuranceReducer.detail
	)

	useEffect(() => {
		dispatch(getTypesInsurance({page: 1}))
	}, [dispatch])

	useEffect(() => {
		if (!isLoadingUpdateTypeInsurance) {
			handleCancelEditTypeInsurance()
		}
	}, [isLoadingUpdateTypeInsurance])

	useEffect(() => {
		if (showError) {
			message.error('Accion realizada incorrectamente')
		} else if (showSuccess) {
			message.success('Accion realizada correctamente')
		}
	}, [showError, showSuccess])

	const [isModalAddTypeInsuranceVisible, setIsModalAddTypeInsuranceVisible] =
		useState(false)

	const [
		isModalEditTypeInsuranceVisible,
		setIsModalEditTypeInsuranceVisible,
	] = useState(false)

	const showModalAddTypeInsurance = () => {
		setIsModalAddTypeInsuranceVisible(true)
	}

	const showModalEditTypeInsurance = () => {
		setIsModalEditTypeInsuranceVisible(true)
	}

	const handleCancelAddTypeInsurance = () => {
		setIsModalAddTypeInsuranceVisible(false)
	}

	const handleCancelEditTypeInsurance = () => {
		setIsModalEditTypeInsuranceVisible(false)
	}

	const handleTypeInsuranceSubmit = (values, actions) => {
		if (values.hasOwnProperty('id')) {
			dispatch(editTypeInsurance(values))
		} else {
			dispatch(addTypeInsurance(values))
		}
		actions.resetForm()
		setIsModalAddTypeInsuranceVisible(false)
	}

	const handleChangePagination = (numberPag) => {
		dispatch(getTypesInsurance({page: numberPag}))
	}

	const handleDelete = (key) => {
		dispatch(deleteTypeInsurance(key))
	}

	const getDetail = (key) => {
		dispatch(getDetailTypesInsurance(key))
		showModalEditTypeInsurance()
	}

	return (
		<LayoutInternal mainTitle="Tipos de seguro">
			<Button
				type="primary"
				style={{marginBottom: 16}}
				onClick={showModalAddTypeInsurance}
			>
				Agregar
			</Button>

			<TypeInsuranceTable
				loading={isLoadingTypeInsuranceList}
				data={listTypesInsurances}
				totalItems={itemsTotalList}
				onPagination={handleChangePagination}
				onDelete={handleDelete}
				getDetail={getDetail}
				currentPage={currentPage}
			/>

			<TypeInsuranceModal
				modalTitle="Agregar tipo de seguro"
				modalVisible={isModalAddTypeInsuranceVisible}
				modalSpinning={isLoadingAddTypeInsurance}
				modalCancel={handleCancelAddTypeInsurance}
				modalSubmit={handleTypeInsuranceSubmit}
				modalFormikInitialValues={{name:''}}
			/>

			<TypeInsuranceModal
				modalTitle="Editar tipo de seguro"
				modalVisible={isModalEditTypeInsuranceVisible}
				modalSpinning={
					isLoadingFetchTypeInsurance || isLoadingUpdateTypeInsurance
				}
				modalCancel={handleCancelEditTypeInsurance}
				modalSubmit={handleTypeInsuranceSubmit}
				modalFormikInitialValues={{
					id: detailRecord.id,
					name: detailRecord.name,
					user: detailRecord.user,
				}}
			/>
		</LayoutInternal>
	)
}

export default TypeInsuranceContainer
