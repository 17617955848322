import React from 'react'
import {Table, Space, Button, Popconfirm} from 'antd'

const {Column} = Table

const PolicyTable = (props) => {
	const {
		loadingTable,
		dataTable,
		totalItems,
		onPagination,
		onDelete,
		getDetail,
		currentPage,
	} = props

	const policyInfo = dataTable.map((info) => {
		let information = {
			key: info.id,
			name: info.client.contact.name,
			first_last_name: info.client.contact.first_last_name,
			policy_number: info.policy_number,
			start_date: info.start_date,
			expires_date: info.expires_date,
			total_payment: info.total_payment,
			status: info.statu,
		}
		return information
	})

	return (
		<Table
			loading={loadingTable}
			dataSource={policyInfo}
			pagination={{
				onChange: (page) => {
					onPagination(page)
				},
				pageSize: process.env.REACT_APP_API_PAGINATION,
				current: currentPage,
				total: totalItems,
			}}
		>
			<Column title="Nombre" dataIndex="name" key="name" />
			<Column
				title="Apellido"
				dataIndex="first_last_name"
				key="first_last_name"
			/>
			<Column
				title="Nº de Poliza"
				dataIndex="policy_number"
				key="policy_number"
			/>
			<Column title="Inicia" dataIndex="start_date" key="start_date" />
			<Column
				title="Finaliza"
				dataIndex="expires_date"
				key="expires_date"
			/>
			<Column
				title="Total"
				dataIndex="total_payment"
				key="total_payment"
			/>
			<Column
				title="Acción"
				key="accion"
				align="center"
				render={(text, record) => (
					<Space size="middle">
						<Button
							type="link"
							onClick={() => {
								getDetail(record.key)
							}}
						>
							Editar
						</Button>
						<Popconfirm
							title="¿Seguro que desea eliminar este registro?"
							okText="Si"
							cancelText="No"
							onConfirm={() => {
								onDelete(record.key)
							}}
						>
							<Button type="link">Eliminar</Button>
						</Popconfirm>
					</Space>
				)}
			/>
		</Table>
	)
}

export default PolicyTable
