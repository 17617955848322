import {axiosService} from './requestService'
import {authHeader} from '../utils/core/functions'

export const addPolicy = async (data) => {
	return axiosService
		.post(`/api/policy/`, (data = {...data}), {
			headers: authHeader(),
		})
		.then((res) => res.data)
}

export const getPolicy = async (data) => {
	return axiosService
		.get(`/api/policylist`, {
			headers: authHeader(),
			params: {
				page: data,
			},
		})
		.then((res) => res.data)
}

export const deletePolicy = async (data) => {
	return axiosService
		.delete(`/api/policy/${data}`, {
			headers: authHeader(),
		})
		.then((res) => res.data)
}

export const editPolicy = async (data) => {
	return axiosService
		.put(`/api/policy/${data.id}/`, (data = {...data}), {
			headers: authHeader(),
		})
		.then((res) => res.data)
}

export const getDetailPolicy = async (data) => {
	return axiosService
		.get(`/api/policy/${data}`, {
			headers: authHeader(),
		})
		.then((res) => res.data)
}
