import {
	ADD_POLICY_REQUEST,
	ADD_POLICY_SUCCESS,
	ADD_POLICY_ERROR,
	GET_POLICY_REQUEST,
	GET_POLICY_SUCCESS,
	GET_POLICY_ERROR,
	GET_DETAIL_POLICY_REQUEST,
	GET_DETAIL_POLICY_SUCCESS,
	GET_DETAIL_POLICY_ERROR,
	DELETE_POLICY_REQUEST,
	DELETE_POLICY_SUCCESS,
	DELETE_POLICY_ERROR,
	EDIT_POLICY_REQUEST,
	EDIT_POLICY_SUCCESS,
	EDIT_POLICY_ERROR,
} from '../actions/policyActions'

const initialState = {
	isLoadingPolicyList: false,
	isLoadingAddPolicy: false,
	isLoadingUpdatePolicy: false,
	isLoadingFetchPolicy: false,
	showError: false,
	showSuccess: false,
	currentPage: 1,
	list: {
		count: 0,
		results: [],
	},
	detail: {
		id: 0,
		user: 0,
		policy_number: "",
		start_date: "",
		expires_date: "",
		total_payment: "",
		status: "", 
		insurance_company: "",
		type_insurance: "",
		client: "",
		agent: "",
		sub_agent: "",
	},
}

export const policyReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_POLICY_REQUEST:
			return {
				...state,
				isLoadingAddPolicy: true,
				showSuccess: false,
				showError: false,
			}
		case ADD_POLICY_SUCCESS:
			return {
				...state,
				isLoadingAddPolicy: false,
				showSuccess: true,
			}
		case ADD_POLICY_ERROR:
			return {
				...state,
				isLoadingAddPolicy: false,
				showError: true,
			}
		case GET_POLICY_REQUEST:
			return {
				...state,
				isLoadingPolicyList: true,
				showSuccess: false,
				showError: false,
			}
		case GET_POLICY_SUCCESS:
			return {
				...state,
				isLoadingPolicyList: false,
				list: action.payload.list,
				currentPage: action.payload.currentPage,
				showSuccess: false, 
				showError: false,
			}
		case GET_POLICY_ERROR:
			return {
				...state,
				isLoadingPolicyList: false,
			}
		case GET_DETAIL_POLICY_REQUEST:
			return {
				...state,
				isLoadingFetchPolicy: true,
			}
		case GET_DETAIL_POLICY_SUCCESS:
			return {
				...state,
				detail: action.payload.detail,
				isLoadingFetchPolicy: false,
			}
		case GET_DETAIL_POLICY_ERROR:
			return {
				...state,
				isLoadingFetchPolicy: false,
			}
		case DELETE_POLICY_REQUEST:
			return {
				...state,
				isLoadingPolicyList: true,
				showError: false,
				showSuccess: true,
			}
		case DELETE_POLICY_SUCCESS:
			return {
				...state,
				isLoadingPolicyList: false,
				currentPage: action.payload.currentPage,
				showSuccess: true,
			}
		case DELETE_POLICY_ERROR:
			return {
				...state,
				isLoadingPolicyList: false,
				showError: true,
			}
		case EDIT_POLICY_REQUEST:
			return {
				...state,
				isLoadingUpdatePolicy: true,
				showError: false,
				showSuccess: false,
			}
		case EDIT_POLICY_SUCCESS:
			return {
				...state,
				isLoadingUpdatePolicy: false,
				showSuccess: true,
			}
		case EDIT_POLICY_ERROR:
			return {
				...state,
				isLoadingUpdatePolicy: false,
				showError: true,
			}
		default:
			return state
	}
}
