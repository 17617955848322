import React from 'react'
import {Table, Space, Popconfirm, Button} from 'antd'

const {Column} = Table

const AgentTable = (props) => {
	const {
		loadingTable,
		dataTable,
		totalItems,
		onPagination,
		onDelete,
		getDetail,
		currentPage,
	} = props

	const agentInfo = dataTable.map(({id,agent_type,rfc,...rest}) => ({
		key:id,
		...rest.contact,
		rfc: (rfc === null)?'No asignado':rfc.fiscal_id,
		agent_type: agent_type,
	}))

	return (
		<Table
			loading={loadingTable}
			dataSource={agentInfo}
			pagination={{
				onChange: (page) => {
					onPagination(page)
				},
				pageSize: process.env.REACT_APP_API_PAGINATION,
				current: currentPage,
				total: totalItems,
			}}
		>
			<Column title="Nombre" dataIndex="name" key="name" />
			<Column
				title="Apellido"
				dataIndex="first_last_name"
				key="first_last_name"
			/>
			<Column title="Telefono" dataIndex="mobile" key="mobile" />
			<Column title="E-mail" dataIndex="email" key="email" />
			<Column title="RFC" dataIndex="rfc" key="rfc" />
			<Column title="Tipo" dataIndex="agent_type" key="agent_type" />
			<Column
				title="Acción"
				key="accion"
				align="center"
				render={(text, record) => (
					<Space size="middle">
						<Button
							type="link"
							onClick={() => {
								getDetail(record.key)
							}}
						>
							Editar
						</Button>
						<Popconfirm
							title="¿Seguro que desea eliminar este registro?"
							okText="Si"
							cancelText="No"
							onConfirm={() => {
								onDelete(record.key)
							}}
						>
							<Button type="link">Eliminar</Button>
						</Popconfirm>
					</Space>
				)}
			/>
		</Table>
	)
}

export default AgentTable
