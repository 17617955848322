import {Layout, Menu} from 'antd'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import Icon from '@ant-design/icons'
import logo from '../../img/agentify.png';
import {logoutUser} from '../../../redux/actions/authUserActions'

import './SiderLayout.css'

const {Sider} = Layout

const SiderLayout = (props) => {
	const dispatch = useDispatch()

	const closeSesion = () => {
		dispatch(logoutUser())
	}

	return (
		<Sider
		style={{background:"#5C215D"}}
			breakpoint="lg"
			collapsedWidth="0"
			onBreakpoint={(broken) => {
				console.log(broken)
			}}
			onCollapse={(collapsed, type) => {
				console.log(collapsed, type)
			}}
		>
			<img className="main-logo" src={logo} alt="agentify-logo"/> 
			<Menu theme="dark" style={{background:"#5C215D", color:"#fff"}} mode="inline">
				<Menu.Item key="1">
					<Icon type="user" />
					<Link to={{pathname: '/insurance-company'}} />
					<span className="nav-text">Aseguradoras</span>
				</Menu.Item>
				<Menu.Item key="2">
					<Icon type="video-camera" />
					<Link to={{pathname: '/type-insurance'}} />
					<span className="nav-text">Tipos de seguro</span>
				</Menu.Item>
				<Menu.Item key="3">
					<Icon type="upload" />
					<Link to={{pathname: '/agent'}} />
					<span className="nav-text">Agentes</span>
				</Menu.Item>
				<Menu.Item key="4">
					<Icon type="user" />
					<Link to={{pathname: '/clients'}} />
					<span className="nav-text">Clientes</span>
				</Menu.Item>
				<Menu.Item key="5">
					<Icon type="user" />
					<Link to={{pathname: '/policy'}} />
					<span className="nav-text">Pólizas</span>
				</Menu.Item>
				<Menu.Item
					key="6"
					onClick={() => {
						closeSesion()
					}}
				>
					<Icon type="user" />
					<span className="nav-text">Cerrar sesión</span>
				</Menu.Item>
			</Menu>
		</Sider>
	)
}

export default SiderLayout
