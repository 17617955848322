import {takeLatest, call, put} from 'redux-saga/effects'
import {
	addAgent,
	getAgent,
	getDetailAgent,
	deleteAgent,
	editAgent,
} from '../../api/agentServices'
import {
	ADD_AGENT_REQUEST,
	ADD_AGENT_SUCCESS,
	ADD_AGENT_ERROR,
	GET_AGENT_REQUEST,
	GET_AGENT_SUCCESS,
	GET_AGENT_ERROR,
	GET_DETAIL_AGENT_REQUEST,
	GET_DETAIL_AGENT_SUCCESS,
	GET_DETAIL_AGENT_ERROR,
	DELETE_AGENT_REQUEST,
	DELETE_AGENT_SUCCESS,
	DELETE_AGENT_ERROR,
	EDIT_AGENT_REQUEST,
	EDIT_AGENT_SUCCESS,
	EDIT_AGENT_ERROR,
} from '../actions/agentActions'

export function* addAgentWatcher() {
	yield takeLatest(ADD_AGENT_REQUEST, addAgentFlow)
}

export function* addAgentFlow(action) {
	try {
		yield call(addAgent, action.payload)
		yield put({type: ADD_AGENT_SUCCESS, payload: {}})
	} catch (e) {
		yield put({type: ADD_AGENT_ERROR, payload: e})
	}
}

export function* addAgentSuccessWatcher() {
	yield takeLatest(ADD_AGENT_SUCCESS, papginationRefresh)
}

export function* getAgentWatcher() {
	yield takeLatest(GET_AGENT_REQUEST, getAgentFlow)
}

export function* getAgentFlow(action) {
	try {
		const agentList = yield call(
			getAgent,
			action.payload.page
		)
		yield put({
			type: GET_AGENT_SUCCESS,
			payload: {
				list: agentList,
				currentPage: action.payload.page,
			},
		})
	} catch (e) {
		yield put({type: GET_AGENT_ERROR, payload: e})
	}
}

export function* getDetailAgentWatcher() {
	yield takeLatest(
		GET_DETAIL_AGENT_REQUEST,
		getDetailAgentFlow
	)
}

export function* getDetailAgentFlow(action) {
	try {
		const detailAgent = yield call(
			getDetailAgent,
			action.payload
		)
		yield put({
			type: GET_DETAIL_AGENT_SUCCESS,
			payload: {
				detail: detailAgent,
			},
		})
	} catch (e) {
		yield put({type: GET_DETAIL_AGENT_ERROR, payload: e})
	}
}

export function* deleteAgentWatcher() {
	yield takeLatest(
		DELETE_AGENT_REQUEST,
		deleteAgentFlow
	)
}

export function* deleteAgentFlow(action) {
	try {
		const agentDel = yield call(
			deleteAgent,
			action.payload
		)
		yield put({
			type: DELETE_AGENT_SUCCESS,
			payload: agentDel,
		})
	} catch (e) {
		yield put({type: DELETE_AGENT_ERROR, payload: e})
	}
}

export function* deleteAgentSuccessWatcher() {
	yield takeLatest(DELETE_AGENT_SUCCESS, papginationRefresh)
}

export function* editAgentWatcher() {
	yield takeLatest(EDIT_AGENT_REQUEST, editAgentFlow)
}

export function* editAgentFlow(action) {
	try {
		yield call(editAgent, action.payload)
		yield put({type: EDIT_AGENT_SUCCESS, payload: {}})
	} catch (e) {
		yield put({type: EDIT_AGENT_ERROR, payload: e})
	}
}

export function* editAgentSuccessWatcher() {
	yield takeLatest(EDIT_AGENT_SUCCESS, papginationRefresh)
}

export function* papginationRefresh(action) {
	yield put({
		type: GET_AGENT_REQUEST,
		payload: {page: 1},
	})
}
