import {takeLatest, call, put} from 'redux-saga/effects'
import {addRfc, getRfc, deleteRfc} from '../../api/rfcServices'
import {
	GET_RFC_REQUEST,
	GET_RFC_SUCCESS,
	GET_RFC_ERROR,
	ADD_RFC_REQUEST,
	ADD_RFC_SUCCESS,
	ADD_RFC_ERROR,
	DELETE_RFC_SUCCESS,
	DELETE_RFC_REQUEST,
	DELETE_RFC_ERROR,
} from '../actions/rfcActions'

export function* addRfcWatcher() {
	yield takeLatest(ADD_RFC_REQUEST, addRfcFlow)
}

export function* addRfcFlow(action) {
	try {
		yield call(addRfc, action.payload)
		yield put({type: ADD_RFC_SUCCESS, payload: {}})
	} catch (e) {
		yield put({type: ADD_RFC_ERROR, payload: e})
	}
}

export function* addRfcSuccessWatcher(){
	yield takeLatest(ADD_RFC_SUCCESS, getRfcFlow)
}

export function* getRfcWatcher() {
	yield takeLatest(GET_RFC_REQUEST, getRfcFlow)
}

export function* getRfcFlow(action) {
	try {
		const rfcList = yield call(getRfc, action.payload.page)
		yield put({
			type: GET_RFC_SUCCESS,
			payload: {
				list: rfcList,
				currentPage: action.payload.currentPage,
			},
		})
	} catch (e) {
		yield put({type: GET_RFC_ERROR, payload: e})
	}
}

export function* deleteRfcWatcher() {
	yield takeLatest(DELETE_RFC_REQUEST, deleteRfcFlow)
}

export function* deleteRfcFlow(action) {
	try {
		const rfcDel = yield call(deleteRfc, action.payload)
		yield put({
			type: DELETE_RFC_SUCCESS,
			payload: rfcDel,
		})
	} catch (e) {
		yield put({type: DELETE_RFC_ERROR, payload: e})
	}
}
