export const ADD_INSURANCE_COMPANY_REQUEST = 'ADD_INSURANCE_COMPANY_REQUEST'
export const ADD_INSURANCE_COMPANY_SUCCESS = 'ADD_INSURANCE_COMPANY_SUCCESS'
export const ADD_INSURANCE_COMPANY_ERROR = 'ADD_INSURANCE_COMPANY_ERROR'

export const GET_INSURANCE_COMPANY_REQUEST = 'GET_INSURANCE_COMPANY_REQUEST'
export const GET_INSURANCE_COMPANY_SUCCESS = 'GET_INSURANCE_COMPANY_SUCCESS'
export const GET_INSURANCE_COMPANY_ERROR = 'GET_INSURANCE_COMPANY_ERROR'

export const GET_DETAIL_INSURANCE_COMPANY_REQUEST =
	'GET_DETAIL_INSURANCE_COMPANY_REQUEST'
export const GET_DETAIL_INSURANCE_COMPANY_SUCCESS =
	'GET_DETAIL_INSURANCE_COMPANY_SUCCESS'
export const GET_DETAIL_INSURANCE_COMPANY_ERROR =
	'GET_DETAIL_INSURANCE_COMPANY_ERROR'

export const DELETE_INSURANCE_COMPANY_REQUEST =
	'DELETE_INSURANCE_COMPANY_REQUEST'
export const DELETE_INSURANCE_COMPANY_SUCCESS =
	'DELETE_INSURANCE_COMPANY_SUCCESS'
export const DELETE_INSURANCE_COMPANY_ERROR = 'DELETE_INSURANCE_COMPANY_ERROR'

export const EDIT_INSURANCE_COMPANY_REQUEST = 'EDIT_INSURANCE_COMPANY_REQUEST'
export const EDIT_INSURANCE_COMPANY_SUCCESS = 'EDIT_INSURANCE_COMPANY_SUCCESS'
export const EDIT_INSURANCE_COMPANY_ERROR = 'EDIT_INSURANCE_COMPANY_ERROR'

export const addInsuranceCompany = (data) => ({
	type: ADD_INSURANCE_COMPANY_REQUEST,
	payload: data,
})

export const getInsuranceCompany = (data) => ({
	type: GET_INSURANCE_COMPANY_REQUEST,
	payload: data,
})

export const getDetailInsuranceCompany = (data) => ({
	type: GET_DETAIL_INSURANCE_COMPANY_REQUEST,
	payload: data,
})

export const deleteInsuranceCompany = (data) => ({
	type: DELETE_INSURANCE_COMPANY_REQUEST,
	payload: data,
})

export const editInsuranceCompany = (data) => ({
	type: EDIT_INSURANCE_COMPANY_REQUEST,
	payload: data,
})
