import {fork} from 'redux-saga/effects'
import {getAuthUserWatcher} from './authUserSaga'
import {
	addTypeInsuranceWatcher,
	getTypeInsuranceWatcher,
	addTypeInsuranceSuccessWatcher,
	deleteTypeInsuranceWatcher,
	deleteTypeInsuranceSuccessWatcher,
	getDetailTypeInsuranceWatcher,
	editTypeInsuranceWatcher,
	editTypeInsuranceSuccessWatcher,
} from './typeInsuranceSaga'

import {
	getClientsWatcher,
	getDetailClientsWatcher,
	deleteClientsWatcher,
	deleteClientsSuccessWatcher,
	editClientsWatcher,
	editClientsSuccessWatcher,
	addClientWatcher,
	addClientSuccessWatcher,
} from './clientsSaga'

import {
	addInsuranceCompanyWatcher,
	getInsuranceCompanyWatcher,
	addInsuranceCompanySuccessWatcher,
	deleteInsuranceCompanyWatcher,
	deleteInsuranceCompanySuccessWatcher,
	getDetailInsuranceCompanyWatcher,
	editInsuranceCompanyWatcher,
	editInsuranceCompanySuccessWatcher,
} from './insuranceCompanySaga'

import {
	addRfcWatcher,
	addRfcFlow,
	addRfcSuccessWatcher,
	getRfcFlow,
	getRfcWatcher,
	deleteRfcFlow,
	deleteRfcWatcher,
} from './rfcSaga'

import {
	addAgentWatcher,
	getAgentWatcher,
	addAgentSuccessWatcher,
	deleteAgentWatcher,
	deleteAgentSuccessWatcher,
	getDetailAgentWatcher,
	editAgentWatcher,
	editAgentSuccessWatcher,
} from './agentSaga'

import {
	addPolicyWatcher,
	getPolicyWatcher,
	addPolicySuccessWatcher,
	deletePolicyWatcher,
	deletePolicySuccessWatcher,
	getDetailPolicyWatcher,
	editPolicyWatcher,
	editPolicySuccessWatcher,
} from './policySaga'

export function* rootSaga() {
	//* Policy watchers
	yield fork(addPolicyWatcher)
	yield fork(getPolicyWatcher)
	yield fork(addPolicySuccessWatcher)
	yield fork(deletePolicyWatcher)
	yield fork(deletePolicySuccessWatcher)
	yield fork(getDetailPolicyWatcher)
	yield fork(editPolicyWatcher)
	yield fork(editPolicySuccessWatcher)

	//* Authentication watcher
	yield fork(getAuthUserWatcher)

	//* Type Insurance watchers
	yield fork(addTypeInsuranceWatcher)
	yield fork(addTypeInsuranceSuccessWatcher)
	yield fork(getTypeInsuranceWatcher)
	yield fork(deleteTypeInsuranceWatcher)
	yield fork(deleteTypeInsuranceSuccessWatcher)
	yield fork(getDetailTypeInsuranceWatcher)
	yield fork(editTypeInsuranceWatcher)
	yield fork(editTypeInsuranceSuccessWatcher)

	//* Agents watchers
	yield fork(addAgentWatcher)
	yield fork(getAgentWatcher)
	yield fork(addAgentSuccessWatcher)
	yield fork(deleteAgentWatcher)
	yield fork(deleteAgentSuccessWatcher)
	yield fork(getDetailAgentWatcher)
	yield fork(editAgentWatcher)
	yield fork(editAgentSuccessWatcher)

	//* Insurance Company watchers
	yield fork(addInsuranceCompanyWatcher)
	yield fork(getInsuranceCompanyWatcher)
	yield fork(addInsuranceCompanySuccessWatcher)
	yield fork(deleteInsuranceCompanyWatcher)
	yield fork(deleteInsuranceCompanySuccessWatcher)
	yield fork(getDetailInsuranceCompanyWatcher)
	yield fork(editInsuranceCompanyWatcher)
	yield fork(editInsuranceCompanySuccessWatcher)

	//* RFC watchers
	yield fork(addRfcWatcher)
	yield fork(addRfcFlow)
	yield fork(addRfcSuccessWatcher)
	yield fork(getRfcFlow)
	yield fork(getRfcWatcher)
	yield fork(deleteRfcFlow)
	yield fork(deleteRfcWatcher)

	//* Clients watchers
	yield fork(getClientsWatcher)
	yield fork(getDetailClientsWatcher)
	yield fork(deleteClientsWatcher)
	yield fork(deleteClientsSuccessWatcher)
	yield fork(editClientsWatcher)
	yield fork(editClientsSuccessWatcher)
	yield fork(addClientWatcher)
	yield fork(addClientSuccessWatcher)
}
