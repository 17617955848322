import {
	GET_CLIENTS_REQUEST,
	GET_CLIENTS_SUCCESS,
	GET_CLIENTS_ERROR,

    GET_DETAIL_CLIENTS_REQUEST,
    GET_DETAIL_CLIENTS_SUCCESS,
    GET_DETAIL_CLIENTS_ERROR,

    DELETE_CLIENTS_REQUEST,
    DELETE_CLIENTS_SUCCESS,
    DELETE_CLIENTS_ERROR,

    EDIT_CLIENTS_REQUEST,
    EDIT_CLIENTS_SUCCESS,
    EDIT_CLIENTS_ERROR,

    ADD_CLIENTS_REQUEST,
    ADD_CLIENTS_SUCCESS,
    ADD_CLIENTS_ERROR

} from '../actions/clientsActions'

const intialState = {
    isLoadingClientsList:false,
    isLoadingAddClient:false,
    isLoadingFetchClients:false,
    isLoadingUpdateClients:false,
    showError:false,
    showSuccess:false,
    erroeMessage:[],
    currentPage: 1,
    list:{
        count:0,
        results:[]
    },
    detail:{
        id: 0,
        gender:'',
        marital_state:'',
        contact:{
            name: '',
            first_last_name:'',
            second_last_name:'',
            email:'',
            mobile:'',
            phone_number:''
        },
        rfc: {
           id:0,
           fiscal_id:'',
           fiscal_name:'',
           fiscal_type:'',
           user:0 
        },
        user:0
    }
}

export const clientsReducer = (state=  intialState, action) => {
    switch (action.type) {
        case GET_CLIENTS_REQUEST:
            return {
                ...state,
                isLoadingClientsList: true,
				showSuccess: false
            }
        case GET_CLIENTS_SUCCESS:
            return{
                ...state,
                isLoadingClientsList: false,
                list: action.payload.list,
                currentPage: action.payload.currentPage,
				showSuccess: false,
                showError: false
            }
        case GET_CLIENTS_ERROR:
            return {
                ...state,
                isLoadingClientsList:false
            }
        case GET_DETAIL_CLIENTS_REQUEST:
            return {
                ...state,
                isLoadingFetchClients: true
            }
        case GET_DETAIL_CLIENTS_SUCCESS:
            return {
                ...state,
                detail: action.payload.detail,
                isLoadingFetchClients: false
            }
        case GET_DETAIL_CLIENTS_ERROR:
            return {
                ...state,
                isLoadingFetchClients:false
            }
        case DELETE_CLIENTS_REQUEST:
            return {
                ...state,
                isLoadingClientsList:true,
                showSuccess:false,
                showError:false
            }
        case DELETE_CLIENTS_SUCCESS:
            return {
                ...state,
                isLoadingClientsList:false,
                currentPage: action.payload.currentPage,
                showSuccess: true
            }
        case DELETE_CLIENTS_ERROR:
            return {
                ...state,
                isLoadingClientsList: false,
                showError: true
            }
        case EDIT_CLIENTS_REQUEST:
            return {
                ...state,
                isLoadingUpdateClients: true,
                showSuccess: false,
                showError: false
            }
        case EDIT_CLIENTS_SUCCESS:
            return {
                ...state,
                isLoadingUpdateClients: false,
                showSuccess: true
            }
        case EDIT_CLIENTS_ERROR:
            return {
                ...state,
                isLoadingUpdateClients: false,
                showError: true
            }
        case ADD_CLIENTS_REQUEST:
            return {
                ...state,
                isLoadingAddClient: true,
                showSuccess: false,
                showError: false
            }
        case ADD_CLIENTS_SUCCESS:
            return {
                ...state,
                isLoadingAddClient: false,
                showSuccess: true
            }
        case ADD_CLIENTS_ERROR:
            return {
                ...state,
                isLoadingAddClient: false,
                errorMessage: action.payload,
                showError: true,
            }
        default:
            return state
    }
}