import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, message} from 'antd'
import LayoutInternal from '../../utils/layout/LayoutInternal/LayoutInternal'
import {
	addInsuranceCompany,
	getInsuranceCompany,
	getDetailInsuranceCompany,
	deleteInsuranceCompany,
	editInsuranceCompany,
} from '../../redux/actions/insuranceCompanyActions'
import InsuranceCompanyTable from '../../components/tables/InsuranceCompany/insuranceCompanyTable'
import InsuranceCompanyModal from '../../components/modals/insuranceCompanyModal'
import {getRfc} from '../../redux/actions/rfcActions'
import RfcModal from '../../components/modals/rfcModal'
import {addRfc} from '../../redux/actions/rfcActions'

const InsuranceCompanyContainer = () => {
	const dispatch = useDispatch()

	const listInsuranceCompany = useSelector(
		(state) => state.insuranceCompanyReducer.list.results
	)

	const {
		isLoadingInsuranceCompanyList,
		isLoadingAddInsuranceCompany,
		isLoadingUpdateInsuranceCompany,
		isLoadingFetchInsuranceCompany,
		showError,
		showSuccess,
	} = useSelector((state) => state.insuranceCompanyReducer)

	const itemsTotalList = useSelector(
		(state) => state.insuranceCompanyReducer.list.count
	)

	const rfcList = useSelector((state) => state.rfcReducer.list.results)

	const currentPage = useSelector(
		(state) => state.insuranceCompanyReducer.currentPage
	)

	const detailRecord = useSelector(
		(state) => state.insuranceCompanyReducer.detail
	)

	useEffect(() => {
		dispatch(getInsuranceCompany({page: 1}))
	}, [dispatch])

	useEffect(() => {
		if (!isLoadingUpdateInsuranceCompany) {
			handleCancelEditInsuranceCompany()
		}
	}, [isLoadingUpdateInsuranceCompany])

	useEffect(() => {
		dispatch(getRfc({page: 1}))
	}, [dispatch])

	useEffect(() => {
		if (showError) {
			message.error('Accion realizada incorrectamente')
		} else if (showSuccess) {
			message.success('Accion realizada correctamente')
		}
	}, [showError, showSuccess])

	const [
		isModalAddInsuranceCompanyVisible,
		setIsModalAddInsuranceCompanyVisible,
	] = useState(false)

	const [
		isModalEditInsuranceCompanyVisible,
		setIsModalEditInsuranceCompanyVisible,
	] = useState(false)

	const showModalAddInsuranceCompany = () => {
		setIsModalAddInsuranceCompanyVisible(true)
	}

	const showModalEditInsuranceCompany = () => {
		setIsModalEditInsuranceCompanyVisible(true)
	}

	const handleCancelAddInsuranceCompany = () => {
		setIsModalAddInsuranceCompanyVisible(false)
	}

	const handleCancelEditInsuranceCompany = () => {
		setIsModalEditInsuranceCompanyVisible(false)
	}

	const handleInsuranceCompanySubmit = (values, actions) => {
		const formatAddValues = {
			name: values.name,
			rfc: values.rfc,
		}

		const formatEditValues = {
			id: values.id,
			name: values.name,
			rfc: values.rfc,
			user: values.user,
		}

		if (values.hasOwnProperty('id')) {
			dispatch(editInsuranceCompany(formatEditValues))
		} else {
			dispatch(addInsuranceCompany(formatAddValues))
		}
		actions.resetForm()
		setIsModalAddInsuranceCompanyVisible(false)
	}

	const handleChangePagination = (numberPag) => {
		dispatch(getInsuranceCompany({page: numberPag}))
	}

	const handleDelete = (key) => {
		dispatch(deleteInsuranceCompany(key))
	}

	const getDetail = (key) => {
		dispatch(getDetailInsuranceCompany(key))
		showModalEditInsuranceCompany()
	}

	// ---------- RFC Modal logic -------------

	const [isModalRfcVisible, setIsModalRfcVisible] = useState(false)

	const onNewRfc = () => {
		setIsModalRfcVisible(true)
	}

	const handleCancelRfc = () => {
		setIsModalRfcVisible(false)
	}

	const handleRfcSubmit = (values, actions) => {
		const customData = {
			fiscal_id: values.fiscal_id,
			fiscal_name: values.fiscal_name,
			fiscal_type: values.fiscal_type,
		}
		dispatch(addRfc(customData))
		actions.resetForm()
		
		setIsModalRfcVisible(false)
	}

	return (
		<LayoutInternal mainTitle="Aseguradoras">
			<Button
				type="primary"
				style={{marginBottom: 16}}
				onClick={showModalAddInsuranceCompany}
			>
				Agregar
			</Button>

			<InsuranceCompanyTable
				loadingTable={isLoadingInsuranceCompanyList}
				dataTable={listInsuranceCompany}
				totalItems={itemsTotalList}
				onPagination={handleChangePagination}
				onDelete={handleDelete}
				getDetail={getDetail}
				currentPage={currentPage}
			/>

			<InsuranceCompanyModal
				modalTitle="Agregar aseguradora"
				modalVisible={isModalAddInsuranceCompanyVisible}
				modalSpinning={isLoadingAddInsuranceCompany}
				modalCancel={handleCancelAddInsuranceCompany}
				modalSubmit={handleInsuranceCompanySubmit}
				modalFormikInitialValues={{name: ''}}
				rfcList={rfcList}
				onNewRfc={onNewRfc}
			/>

			<InsuranceCompanyModal
				modalTitle="Editar aseguradora"
				modalVisible={isModalEditInsuranceCompanyVisible}
				modalSpinning={
					isLoadingFetchInsuranceCompany ||
					isLoadingUpdateInsuranceCompany
				}
				modalCancel={handleCancelEditInsuranceCompany}
				modalSubmit={handleInsuranceCompanySubmit}
				modalFormikInitialValues={{
					id: detailRecord.id,
					user: detailRecord.user,
					name: detailRecord.name,
					rfc: detailRecord.rfc,
				}}
				rfcList={rfcList}
				onNewRfc={onNewRfc}
			/>

			<RfcModal
				modalTitle="Agregar nuevo RFC"
				modalVisible={isModalRfcVisible}
				modalCancel={handleCancelRfc}
				modalSubmit={handleRfcSubmit}
				modalFormikInitialValues={{
					fiscal_id: '',
					fiscal_name: '',
					fiscal_type: '',
				}}
			/>
		</LayoutInternal>
	)
}

export default InsuranceCompanyContainer
