import React from 'react'
import {Form, Input, SubmitButton} from 'formik-antd'
import {Button} from 'antd'

const TypeInsuranceForm = ({onCancel}) => {
	return (
		<Form preserve={false}>
			<Form.Item name="name" label="Nombre" required={true}>
				<Input name="name" placeholder="Tipo de seguro" />
			</Form.Item>

			<Button
				style={{marginInline: 5, marginLeft: 270}}
				onClick={() => onCancel()}
			>
				Cancelar
			</Button>
			<SubmitButton>Guardar</SubmitButton>
		</Form>
	)
}

export default TypeInsuranceForm
