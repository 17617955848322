import React from 'react'
import {Form, Input, SubmitButton, Select} from 'formik-antd'
import {Button} from 'antd'

const InsuranceCompanyForm = (props) => {
	const {onNewRfc, rfcList} = props

	const cascaderData = rfcList.map(({id,fiscal_id}) => ({
		key: id,
		value: id,
		label: fiscal_id,
	}))

	const layout = {
		labelCol: {span: 7},
		wrapperCol: {span: 16},
	}

	return (
		<Form {...layout} preserve={false}>
			<Form.Item name="name" label="Nombre" required={true}>
				<Input name="name" placeholder="Nombre de la aseguradora" />
			</Form.Item>

			<Form.Item
				name="rfc"
				label="RFC"
				rules={[
					{
						required: true,
						message: 'Hace falta el RFC',
					},
				]}
			>
				<Select
					showSearch={true}
					name="rfc"
					placeholder="Selecciona un RFC"
					filterOption={(input, option) =>
						option.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}
					filterSort={(optionA, optionB) =>
						optionA.children
							.toLowerCase()
							.localeCompare(optionB.children.toLowerCase())
					}
				>
					{cascaderData.map((option) => (
						<Select.Option key={option.key} value={option.value}>
							{option.label}
						</Select.Option>
					))}
				</Select>
				<Button type="link" onClick={onNewRfc}>
					Agregar Nuevo RFC
				</Button>
			</Form.Item>
			<Button
				style={{marginInline: 5, marginLeft: 270}}
				onClick={() => props.onCancel()}
			>
				Cancelar
			</Button>
			<SubmitButton>Guardar</SubmitButton>
		</Form>
	)
}

export default InsuranceCompanyForm
