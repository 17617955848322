import React from 'react'
import {Modal, Spin} from 'antd'
import {Formik} from 'formik'
import AgentForm from '../forms/AgentForm/AgentForm'
import AgentFormValidation from '../forms/AgentForm/AgentValidationForm'

const AgentModal = (props) => {
    const { 
        modalTitle,
		modalVisible,
		modalSpinning,
		modalCancel,
		modalSubmit,
		modalFormikInitialValues,
		onNewRfc,
		rfcList,
    } = props

    return (
        <Modal
            title={modalTitle}
			visible={modalVisible}
			onCancel={modalCancel}
			footer={null}
			confirmLoading={true}
			keyboard={true}
			destroyOnClose={true}
			width={750}
        >
            <Spin spinning={modalSpinning}>
				<Formik
					validationSchema={AgentFormValidation}
					onSubmit={modalSubmit}
					initialValues={modalFormikInitialValues}
					enableReinitialize={true}
				>
					{() => (
						<AgentForm
							onNewRfc={onNewRfc}
							rfcList={rfcList}
							onCancel={modalCancel}
						/>
					)}
				</Formik>
			</Spin>   
        </Modal>
    )
}

export default AgentModal