import React from 'react'
import {Modal, Spin} from 'antd'
import {Formik} from 'formik'
import InsuranceCompanyForm from '../forms/InsuranceCompanyForm/insurnaceCompanyForm'
import insuranceCompanyValidation from '../forms/InsuranceCompanyForm/insuranceCompanyValidation'

const InsuranceCompanyModal = (props) => {
	const {
		modalTitle,
		modalVisible,
		modalSpinning,
		modalCancel,
		modalSubmit,
		modalFormikInitialValues,
		onNewRfc,
		rfcList,
	} = props

	return (
		<Modal
			title={modalTitle}
			visible={modalVisible}
			onCancel={modalCancel}
			footer={null}
			confirmLoading={true}
			keyboard={true}
			destroyOnClose={true}
		>
			<Spin spinning={modalSpinning}>
				<Formik
					validationSchema={insuranceCompanyValidation}
					onSubmit={modalSubmit}
					initialValues={modalFormikInitialValues}
					enableReinitialize={true}
				>
					{() => (
						<InsuranceCompanyForm
							onNewRfc={onNewRfc}
							rfcList={rfcList}
							onCancel={modalCancel}
						/>
					)}
				</Formik>
			</Spin>
		</Modal>
	)
}

export default InsuranceCompanyModal
