import {axiosService} from './requestService'
import {authHeader} from '../utils/core/functions'

export const getClients = async (data) => {
	try {
		const res = await axiosService.get(`/api/clientlist`, {
			headers: authHeader(),
			params: {
				page: data,
			},
		})
        return res.data
	} catch (error) {
        throw error.response.data
    }
}

export const getDetailClients = async (data) => {
	try {
		const res = await axiosService.get(`/api/client/${data}`, {
			headers: authHeader(),
		})
		return res.data
	} catch (error) {
		throw error.response.data
	}
}

export const deleteClients = async (data) => {
	try {
		const res = await axiosService.delete(`/api/client/${data}`, {
			headers: authHeader(),
		})
		return res.data
	} catch (error) {
		throw error.response.data
	}
}

export const editClients = async (data) => {
	try {
		const res = await axiosService.put(
			`/api/client/${data.id}/`,
			(data = {...data}),
			{headers: authHeader()}
		)
		return res.data
	} catch (error) {
		throw error.response.data
	}
}

export const addClients = async (data) => {
	try {
		const res = await axiosService.post(`/api/client/`, data, {
			headers: authHeader(),
		})
		return res.data
	} catch (error) {
		throw error.response.data
	}
}
