import {
	GET_RFC_REQUEST,
	GET_RFC_SUCCESS,
	GET_RFC_ERROR,
	ADD_RFC_REQUEST,
	ADD_RFC_SUCCESS,
	ADD_RFC_ERROR,
	DELETE_RFC_SUCCESS,
	DELETE_RFC_REQUEST,
	DELETE_RFC_ERROR,
} from '../actions/rfcActions'

const initialState = {
	currentPage: 1,
	list: {
		count: 0,
		results: [],
	},
}

export const rfcReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_RFC_REQUEST:
			return {
				...state,
			}
		case ADD_RFC_SUCCESS:
			return {
				...state,
			}
		case ADD_RFC_ERROR:
			return {
				...state,
			}
		case GET_RFC_REQUEST:
			return {
				...state,
			}
		case GET_RFC_SUCCESS:
			return {
				...state,
				list: action.payload.list,
				currentPage: action.payload.currentPage,
			}
		case GET_RFC_ERROR:
			return {
				...state,
			}
		case DELETE_RFC_REQUEST:
			return {
				...state,
			}
		case DELETE_RFC_SUCCESS:
			return {
				...state,
				currentPage: action.payload.currentPage,
			}
		case DELETE_RFC_ERROR:
			return {
				...state,
			}
		default:
			return state
	}
}
