import {
	GET_TYPES_INSURANCE_REQUEST,
	GET_TYPES_INSURANCE_SUCCESS,
	GET_TYPES_INSURANCE_ERROR,
	GET_DETAIL_TYPE_INSURANCE_REQUEST,
	GET_DETAIL_TYPE_INSURANCE_SUCCESS,
	GET_DETAIL_TYPE_INSURANCE_ERROR,
	ADD_TYPE_INSURANCE_REQUEST,
	ADD_TYPE_INSURANCE_SUCCESS,
	ADD_TYPE_INSURANCE_ERROR,
	EDIT_TYPE_INSURANCE_REQUEST,
	EDIT_TYPE_INSURANCE_SUCCESS,
	EDIT_TYPE_INSURANCE_ERROR,
	DELETE_TYPE_INSURANCE_SUCCESS,
	DELETE_TYPE_INSURANCE_REQUEST,
	DELETE_TYPE_INSURANCE_ERROR,
} from '../actions/typeInsuranceActions'

const initialState = {
	isLoadingTypeInsuranceList: false,
	isLoadingAddTypeInsurance: false,
	isLoadingUpdateTypeInsurance: false,
	isLoadingFetchTypeInsurance: false,
	showError: false,
	showSuccess: false,
	currentPage: 1,
	list: {
		count: 0,
		results: [],
	},
	detail: {
		id: 0,
		name: '',
		user: 0,
	},
}

export const typeInsuranceReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_TYPE_INSURANCE_REQUEST:
			return {
				...state,
				isLoadingAddTypeInsurance: true,
				showSuccess: false,
				showError: false,
			}
		case ADD_TYPE_INSURANCE_SUCCESS:
			return {
				...state,
				isLoadingAddTypeInsurance: false,
				showSuccess: true,
			}
		case ADD_TYPE_INSURANCE_ERROR:
			return {
				...state,
				isLoadingAddTypeInsurance: false,
				showError: true,
			}
		case EDIT_TYPE_INSURANCE_REQUEST:
			return {
				...state,
				isLoadingUpdateTypeInsurance: true,
				showSuccess: false,
				showError: false,
			}
		case EDIT_TYPE_INSURANCE_SUCCESS:
			return {
				...state,
				isLoadingUpdateTypeInsurance: false,
				showSuccess: true,
			}
		case EDIT_TYPE_INSURANCE_ERROR:
			return {
				...state,
				isLoadingUpdateTypeInsurance: false,
				showError: true,
			}
		case GET_TYPES_INSURANCE_REQUEST:
			return {
				...state,
				isLoadingTypeInsuranceList: true,
				showSuccess: false
			}
		case GET_TYPES_INSURANCE_SUCCESS:
			return {
				...state,
				isLoadingTypeInsuranceList: false,
				list: action.payload.list,
				currentPage: action.payload.currentPage,
				showSuccess: false,
			}
		case GET_TYPES_INSURANCE_ERROR:
			return {
				...state,
				isLoadingTypeInsuranceList: false,
			}
		case GET_DETAIL_TYPE_INSURANCE_REQUEST:
			return {
				...state,
				isLoadingFetchTypeInsurance: true,
			}
		case GET_DETAIL_TYPE_INSURANCE_SUCCESS:
			return {
				...state,
				detail: action.payload.detail,
				isLoadingFetchTypeInsurance: false,
			}
		case GET_DETAIL_TYPE_INSURANCE_ERROR:
			return {
				...state,
				isLoadingFetchTypeInsurance: false,
			}
		case DELETE_TYPE_INSURANCE_REQUEST:
			return {
				...state,
				isLoadingTypeInsuranceList: true,
				showSuccess: false,
				showError: false,
			}
		case DELETE_TYPE_INSURANCE_SUCCESS:
			return {
				...state,
				isLoadingTypeInsuranceList: false,
				currentPage: action.payload.currentPage,
				showSuccess: true,
			}
		case DELETE_TYPE_INSURANCE_ERROR:
			return {
				...state,
				isLoadingTypeInsuranceList: false,
				showError: true,
			}
		default:
			return state
	}
}
