import {axiosService} from './requestService'
import {authHeader} from '../utils/core/functions'

export const addRfc = async (data) => {
	return axiosService
		.post(`/api/rfc/`, (data = {...data}), {
			headers: authHeader(),
		})
		.then((res) => res.data)
}

export const getRfc = async (data) => {
	return axiosService
		.get(`/api/rfc/`, {
			headers: authHeader(),
			params: {
				page: data,
			},
		})
		.then((res) => res.data)
}

export const deleteRfc = async (data) => {
	return axiosService
		.delete(`/api/rfc/${data}`, {
			headers: authHeader(),
		})
		.then((res) => res.data)
}
