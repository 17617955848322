import React from 'react'
import {Form, Input, SubmitButton, Select} from 'formik-antd'
import {Button} from 'antd'

const ClientForm = (props) => {
	const {onNewRfc, rfcList} = props
	const optionrfc = rfcList.map(({id,fiscal_id}) => ({
		key:id,
		value: id,
		label: fiscal_id,
	}))

	const layout = {
		labelCol: { span: 7 },
		wrapperCol: { span: 16 },
	}

	const optionGender = [
		{value: 'MASCULINE', label: 'Hombre'},
		{value: 'FEMININE', label: 'Mujer'},
	]
	const optionMaritalStatus = [
		{value: 'SINGLE', label: 'Soltero'},
		{value: 'MARRIED', label: 'Casado'},
	]
	return (
		<Form {...layout} preserve={false}>
			<Form.Item name="name" label="Nombre" required={true}>
				<Input name="name" placeholder="Nombre" />
			</Form.Item>
			<Form.Item
				name="first_last_name"
				label="Apellido Paterno"
				required={true}
			>
				<Input name="first_last_name" placeholder="Apellido Paterno" />
			</Form.Item>
			<Form.Item
				name="second_last_name"
				label="Apellido Materno"
			>
				<Input name="second_last_name" placeholder="Apellido Materno" />
			</Form.Item>
			<Form.Item name="email" label="Correo" required={true}>
				<Input name="email" placeholder="Correo" />
			</Form.Item>
			<Form.Item name="mobile" label="Celular" required={true}>
				<Input name="mobile" placeholder="Celular" />
			</Form.Item>
			<Form.Item name="phone_number" label="Teléfono">
				<Input name="phone_number" placeholder="Teléfono" />
			</Form.Item>
			<Form.Item name="gender" label="Genero" required={true}>
				<Select
					name="gender"
					options={optionGender}
					placeholder="Seleccione un Genero"
				/>
			</Form.Item>
			<Form.Item
				name="marital_state"
				label="Estado Civil"
			>
				<Select
					name="marital_state"
					options={optionMaritalStatus}
					placeholder="Seleccione su estado civil"
				/>
			</Form.Item>
			<Form.Item name="rfc" label="RFC">
				<Select
					showSearch={true}
					name="rfc"
					placeholder="seccione un RFC"
					optionFilterProp="children"
					filterOption={(input, option) =>
						option.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}
					filterSort={(optionA, optionB) =>
						optionA.children
							.toLowerCase()
							.localeCompare(optionB.children.toLowerCase())
					}
				>
					{optionrfc.map((option) => (
						<Select.Option key={option.key} value={option.value}>
							{option.label}
						</Select.Option>
					))}
				</Select>
				<Button type="link" onClick={onNewRfc}>
					Agregar Nuevo RFC
				</Button>
			</Form.Item>
			<Button
				style={{marginInline: 5, marginLeft: 270}}
				onClick={() => props.onCancel()}
			>
				Cancelar
			</Button>
			<SubmitButton>Guardar</SubmitButton>
		</Form>
	)
}

export default ClientForm
