import {
	ADD_AGENT_REQUEST,
	ADD_AGENT_SUCCESS,
	ADD_AGENT_ERROR,
	GET_AGENT_REQUEST,
	GET_AGENT_SUCCESS,
	GET_AGENT_ERROR,
	GET_DETAIL_AGENT_REQUEST,
	GET_DETAIL_AGENT_SUCCESS,
	GET_DETAIL_AGENT_ERROR,
	DELETE_AGENT_REQUEST,
	DELETE_AGENT_SUCCESS,
	DELETE_AGENT_ERROR,
	EDIT_AGENT_REQUEST,
	EDIT_AGENT_SUCCESS,
	EDIT_AGENT_ERROR,
} from '../actions/agentActions'

const initialState = {
	isLoadingAgentList: false,
	isLoadingAddAgent: false,
	isLoadingUpdateAgent: false,
	isLoadingFetchAgent: false,
	showError: false,
	showSuccess: false,
	currentPage: 1,
	list: {
		count: 0,
		results: [],
	},
	detail: {
		id: '',
		user: '',
		contact: {
			id: '',
			name: '',
			first_last_name: '',
			second_last_name: '',
			email: '',
			phone_number: '',
			mobile: '',
		},
		rfc: 0,
		agent_type: '',
	},
}

export const agentReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_AGENT_REQUEST:
			return {
				...state,
				isLoadingAddAgent: true,
				showSuccess: false,
				showError: false,
			}
		case ADD_AGENT_SUCCESS:
			return {
				...state,
				isLoadingAddAgent: false,
				showSuccess: true,
			}
		case ADD_AGENT_ERROR:
			return {
				...state,
				isLoadingAddAgent: false,
				showError: true,
			}
		case GET_AGENT_REQUEST:
			return {
				...state,
				isLoadingAgentList: true,
				showSuccess: false
			}
		case GET_AGENT_SUCCESS:
			return {
				...state,
				isLoadingAgentList: false,
				list: action.payload.list,
				currentPage: action.payload.currentPage,
				showSuccess: false
			}
		case GET_AGENT_ERROR:
			return {
				...state,
				isLoadingAgentList: false,
			}
		case GET_DETAIL_AGENT_REQUEST:
			return {
				...state,
				isLoadingFetchAgent: true,
			}
		case GET_DETAIL_AGENT_SUCCESS:
			return {
				...state,
				isLoadingFetchAgent: false,
				detail: action.payload.detail,
			}
		case GET_DETAIL_AGENT_ERROR:
			return {
				...state,
				isLoadingFetchAgent: false,
			}
		case DELETE_AGENT_REQUEST:
			return {
				...state,
				isLoadingAgentList: true,
				showSuccess: false,
				showError: false,
			}
		case DELETE_AGENT_SUCCESS:
			return {
				...state,
				isLoadingAgentList: true,
				showSuccess: true,
				currentPage: action.payload.currentPage,
			}
		case DELETE_AGENT_ERROR:
			return {
				...state,
				isLoadingAgentList: false,
				showError: true,
			}
		case EDIT_AGENT_REQUEST:
			return {
				...state,
				isLoadingUpdateAgent: true,
				showSuccess: false,
				showError: false,
			}
		case EDIT_AGENT_SUCCESS:
			return {
				...state,
				isLoadingUpdateAgent: false,
				showSuccess: true,
			}
		case EDIT_AGENT_ERROR:
			return {
				...state,
				isLoadingUpdateAgent: false,
				showError: true,
			}
		default:
			return state
	}
}
