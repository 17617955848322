import React from 'react'
import {Modal} from 'antd'
import {Formik} from 'formik'
import RfcForm from '../forms/RfcForm/Rfcform'
import RfcFormValidation from '../forms/RfcForm/RfcFormValidation'

const RfcModal = (props) => {
	const {
		modalTitle,
		modalVisible,
		modalCancel,
		modalSubmit,
		modalFormikInitialValues,
	} = props

	return (
		<Modal
			title={modalTitle}
			visible={modalVisible}
			onCancel={modalCancel}
			footer={null}
			confirmLoading={true}
			keyboard={true}
			destroyOnClose={true}
		>
			<Formik
				validationSchema={RfcFormValidation}
				onSubmit={modalSubmit}
				initialValues={modalFormikInitialValues}
				enableReinitialize={true}
			>
				{() => <RfcForm onCancel={modalCancel} />}
			</Formik>
		</Modal>
	)
}

export default RfcModal
