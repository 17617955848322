import React from 'react'
import {Drawer, Spin} from 'antd'
import PolicyForm from '../forms/PolicyForm/PolicyForm'

const PolicyDrawer = (props) => {
	const {
		policyForm,
		drawerTitle,
		drawerVisible,
		drawerOnClose,
		drawerStyle,
		drawerSpinning,
		drawerSubmit,
		insuranceCompanyList,
		onNewInsuranceCompany,
		typeInsuranceList,
		onNewTypeInsurance,
		clientList,
		onNewClient,
		agentList,
		onNewAgent,
	} = props

	return (
		<Drawer
			title={drawerTitle}
			visible={drawerVisible}
			onClose={drawerOnClose}
			bodyStyle={drawerStyle}
			width={720}
			destroyOnClose={true}
			placement={'right'}
			getContainer={false}
		>
			<Spin spinning={drawerSpinning}>
				<PolicyForm
					policyForm={policyForm}
					insuranceCompanyList={insuranceCompanyList}
					onNewInsuranceCompany={onNewInsuranceCompany}
					typeInsuranceList={typeInsuranceList}
					onNewTypeInsurance={onNewTypeInsurance}
					clientList={clientList}
					onNewClient={onNewClient}
					agentList={agentList}
					onNewAgent={onNewAgent}
					onCancel={drawerOnClose}
					onSubmit={drawerSubmit}
				/>
			</Spin>
		</Drawer>
	)
}

export default PolicyDrawer
