import React from 'react'
import {Table, Space, Button, Popconfirm} from 'antd'

const {Column} = Table

const InsuranceCompanyTable = (props) => {
	const {
		loadingTable,
		dataTable,
		totalItems,
		onPagination,
		onDelete,
		getDetail,
		currentPage,
	} = props

	const insuranceCompanyInfo = dataTable.map(({id,name,rfc}) => ({
		key: id,
		name: name,
		rfc: (rfc === null)?'No asignado':rfc.fiscal_id,
	}))

	return (
		<>
			<Table
				loading={loadingTable}
				dataSource={insuranceCompanyInfo}
				pagination={{
					onChange: (page) => {
						onPagination(page)
					},
					pageSize: process.env.REACT_APP_API_PAGINATION,
					current: currentPage,
					total: totalItems,
				}}
			>
				<Column
					title="Compañia Aseguradora"
					dataIndex="name"
					key="name"
				/>
				<Column title="RFC" dataIndex="rfc" key="rfc" />
				<Column
					title="Acción"
					key="accion"
					align="center"
					render={(text, record) => (
						<Space size="middle">
							<Button
								type="link"
								onClick={() => {
									getDetail(record.key)
								}}
							>
								Editar
							</Button>
							<Popconfirm
								title="¿Seguro que desea eliminar este registro?"
								okText="Si"
								cancelText="No"
								onConfirm={() => {
									onDelete(record.key)
								}}
							>
								<Button type="link">Eliminar</Button>
							</Popconfirm>
						</Space>
					)}
				/>
			</Table>
		</>
	)
}

export default InsuranceCompanyTable
