import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, message} from 'antd'
import LayoutInternal from '../../utils/layout/LayoutInternal/LayoutInternal'
import {
	addAgent,
	getAgent,
	getDetailAgent,
	deleteAgent,
	editAgent,
} from '../../redux/actions/agentActions'
import AgentTable from '../../components/tables/AgentTable/AgentTable'
import AgentModal from '../../components/modals/agentModal'
import {getRfc} from '../../redux/actions/rfcActions'
import RfcModal from '../../components/modals/rfcModal'
import {addRfc} from '../../redux/actions/rfcActions'

const AgentContainer = () => {
	const dispatch = useDispatch()

	const listAgent = useSelector((state) => state.agentReducer.list.results)

	const {
		isLoadingAgentList,
		isLoadingAddAgent,
		isLoadingUpdateAgent,
		isLoadingFetchAgent,
		showError,
		showSuccess,
	} = useSelector((state) => state.agentReducer)

	const itemsTotalList = useSelector((state) => state.agentReducer.list.count)

	const rfcList = useSelector((state) => state.rfcReducer.list.results)

	const currentPage = useSelector((state) => state.agentReducer.currentPage)

	const detailRecord = useSelector((state) => state.agentReducer.detail)

	useEffect(() => {
		dispatch(getAgent({page: 1}))
	}, [dispatch])

	useEffect(() => {
		if (!isLoadingUpdateAgent) {
			handleCancelEditAgent()
		}
	}, [isLoadingUpdateAgent])

	useEffect(() => {
		dispatch(getRfc({page: 1}))
	}, [dispatch])

	useEffect(() => {
		if (showError) {
			message.error('Accion realizada incorrectamente')
		} else if (showSuccess) {
			message.success('Accion realizada correctamente')
		}
	}, [showError, showSuccess])

	const [isModalAddAgentVisible, setIsModalAddAgentVisible] = useState(false)

	const [isModalEditAgentVisible, setIsModalEditAgentVisible] =
		useState(false)

	const showModalAddAgent = () => {
		setIsModalAddAgentVisible(true)
	}

	const showModalEditAgent = () => {
		setIsModalEditAgentVisible(true)
	}

	const handleCancelAddAgent = () => {
		setIsModalAddAgentVisible(false)
	}

	const handleCancelEditAgent = () => {
		setIsModalEditAgentVisible(false)
	}

	const handleAgentSubmit = (values, actions) => {
		const formatAddValues = {
			contact: {
				name: values.name,
				first_last_name: values.first_last_name,
				second_last_name: values.second_last_name,
				email: values.email,
				phone_number: values.phone_number,
				mobile: values.mobile,
			},
			rfc: values.rfc,
			agent_type: values.agent_type
		}

		const formatEditValues = {
			id: values.id,
			user: values.user,
			contact: {
				name: values.name,
				first_last_name: values.first_last_name,
				second_last_name: values.second_last_name,
				email: values.email,
				phone_number: values.phone_number,
				mobile: values.mobile,
			},
			rfc: values.rfc,
			agent_type: values.agent_type
		}

		values.hasOwnProperty('id')
			? dispatch(editAgent(formatEditValues))
			: dispatch(addAgent(formatAddValues))

		actions.resetForm()
		setIsModalAddAgentVisible(false)
	}

	const handleChangePagination = (numberPag) => {
		dispatch(getAgent({page: numberPag}))
	}

	const handleDelete = (key) => {
		dispatch(deleteAgent(key))
	}

	const getDetail = (key) => {
		dispatch(getDetailAgent(key))
		showModalEditAgent()
	}

	// ---------- RFC Modal logic -------------

	const [isModalRfcVisible, setIsModalRfcVisible] = useState(false)

	const onNewRfc = () => {
		setIsModalRfcVisible(true)
	}

	const handleCancelRfc = () => {
		setIsModalRfcVisible(false)
	}

	const handleRfcSubmit = (values, actions) => {
		const customData = {
			fiscal_id: values.fiscal_id,
			fiscal_name: values.fiscal_name,
			fiscal_type: values.fiscal_type,
		}
		dispatch(addRfc(customData))
		actions.resetForm()

		setIsModalRfcVisible(false)
	}

	return (
		<LayoutInternal mainTitle="Agentes">
			<Button
				type="primary"
				style={{marginBottom: 16}}
				onClick={showModalAddAgent}
			>
				Agregar
			</Button>

			<AgentTable
				loadingTable={isLoadingAgentList}
				dataTable={listAgent}
				totalItems={itemsTotalList}
				onPagination={handleChangePagination}
				onDelete={handleDelete}
				getDetail={getDetail}
				currentPage={currentPage}
			/>

			<AgentModal
				modalTitle="Agregar agente"
				modalVisible={isModalAddAgentVisible}
				modalSpinning={isLoadingAddAgent}
				modalCancel={handleCancelAddAgent}
				modalSubmit={handleAgentSubmit}
				modalFormikInitialValues={{
					name: '',
					first_last_name: '',
					email: '',
					mobile: '',
					agent_type: ''
				}}
				rfcList={rfcList}
				onNewRfc={onNewRfc}
			/>

			<AgentModal
				modalTitle="Editar agente"
				modalVisible={isModalEditAgentVisible}
				modalSpinning={isLoadingFetchAgent || isLoadingUpdateAgent}
				modalCancel={handleCancelEditAgent}
				modalSubmit={handleAgentSubmit}
				modalFormikInitialValues={{
					id: detailRecord.id,
					user: detailRecord.user,
					name: detailRecord.contact.name,
					first_last_name: detailRecord.contact.first_last_name,
					second_last_name: detailRecord.contact.second_last_name,
					email: detailRecord.contact.email,
					phone_number: detailRecord.contact.phone_number,
					mobile: detailRecord.contact.mobile,
					rfc: detailRecord.rfc,
					agent_type: detailRecord.agent_type,
				}}
				rfcList={rfcList}
				onNewRfc={onNewRfc}
			/>

			<RfcModal
				modalTitle="Agregar nuevo RFC"
				modalVisible={isModalRfcVisible}
				modalCancel={handleCancelRfc}
				modalSubmit={handleRfcSubmit}
				modalFormikInitialValues={{
					fiscal_id: '',
					fiscal_name: '',
					fiscal_type: '',
				}}
			/>
		</LayoutInternal>
	)
}

export default AgentContainer
