export const ADD_RFC_REQUEST = 'ADD_RFC_REQUEST'
export const ADD_RFC_SUCCESS = 'ADD_RFC_SUCCESS'
export const ADD_RFC_ERROR = 'ADD_RFC_ERROR'

export const GET_RFC_REQUEST = 'GET_RFC_REQUEST'
export const GET_RFC_SUCCESS = 'GET_RFC_SUCCESS'
export const GET_RFC_ERROR = 'GET_RFC_ERROR'

export const DELETE_RFC_REQUEST = 'DELETE_RFC_REQUEST'
export const DELETE_RFC_SUCCESS = 'DELETE_RFC_SUCCESS'
export const DELETE_RFC_ERROR = 'DELETE_RFC_ERROR'

export const addRfc = (data) => ({
	type: ADD_RFC_REQUEST,
	payload: data,
})

export const getRfc = (data) => ({
	type: GET_RFC_REQUEST,
	payload: data,
})

export const deleteRfc = (data) => ({
	type: DELETE_RFC_REQUEST,
	payload: data,
})
