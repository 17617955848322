import React from 'react'
import {Table, Space, Button, Popconfirm} from 'antd'

const {Column} = Table

const TypeInsuranceTable = (props) => {
	const {
		loading,
		data,
		totalItems,
		onPagination,
		onDelete,
		getDetail,
		currentPage,
	} = props

	const typeInsuranceInfo = data.map(info => ({
		key: info.id,
		name: info.name
	}))

	return (
		<>
			<Table
				loading={loading}
				dataSource={typeInsuranceInfo}
				pagination={{
					onChange: (page) => {
						onPagination(page)
					},
					pageSize: process.env.REACT_APP_API_PAGINATION,
					current: currentPage,
					total: totalItems,
				}}
			>
				<Column title="Tipo de seguro" dataIndex="name" key="name" />
				<Column
					title="Accion"
					key="accion"
					align="center"
					render={(text, record) => (
						<Space size="middle">
							<Button
								type="link"
								onClick={() => {
									getDetail(record.key)
								}}
							>
								Editar
							</Button>
							<Popconfirm
								title="¿Seguro que desea eliminar este registro?"
								okText="Si"
								cancelText="No"
								onConfirm={() => {
									onDelete(record.key)
								}}
							>
								<Button type="link">Eliminar</Button>
							</Popconfirm>
						</Space>
					)}
				/>
			</Table>
		</>
	)
}

export default TypeInsuranceTable
