import {axiosService} from './requestService'
import {authHeader} from '../utils/core/functions'

export const addTypeInsurance = async (data) => {
	return axiosService
		.post(`/api/typeinsurance/`, (data = {...data}), {
			headers: authHeader(),
		})
		.then((res) => res.data)
}

export const getTypeInsurance = async (data) => {
	return axiosService
		.get(`/api/typeinsurance/`, {
			headers: authHeader(),
			params: {
				page: data,
			},
		})
		.then((res) => res.data)
}

export const deleteTypeInsurance = async (data) => {
	return axiosService
		.delete(`/api/typeinsurance/${data}`, {
			headers: authHeader(),
		})
		.then((res) => res.data)
}

export const editTypeInsurance = async (data) => {
	return axiosService
		.put(`/api/typeinsurance/${data.id}/`, (data = {...data}), {
			headers: authHeader(),
		})
		.then((res) => res.data)
}

export const getDetailTypeInsurance = async (data) => {
	return axiosService
		.get(`/api/typeinsurance/${data}`, {
			headers: authHeader(),
		})
		.then((res) => res.data)
}
