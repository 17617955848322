import React from 'react'
import {Form, Input, SubmitButton, Select} from 'formik-antd'
import {Button} from 'antd'

const AgentForm = (props) => {
	const {onNewRfc, rfcList} = props

	const cascaderData = rfcList.map(({id,fiscal_id}) => ({
		key: id,
		value: id,
		label: fiscal_id
	}))

	const optionCascader = [
		{
			value: 'AGENT',
			label: 'Agente',
		},
		{
			value: 'SUBAGENT',
			label: 'Sub Agente',
		},
	]
	const layout = {
		labelCol: {span: 7},
		wrapperCol: {span: 16},
	}
	return (
		
		<Form {...layout} preserve={false}>
			<Form.Item name="name" label="Nombre" required={true}>
				<Input name="name" />
			</Form.Item>
			<Form.Item
				name="first_last_name"
				label="Apellido Paterno"
				required={true}
			>
				<Input name="first_last_name" />
			</Form.Item>
			<Form.Item
				name="second_last_name"
				label="Apellido Materno"
			>
				<Input name="second_last_name" />
			</Form.Item>

			<Form.Item name="phone_number" label="Telefono">
				<Input name="phone_number" />
			</Form.Item>
			
			<Form.Item name="mobile" label="Celular" required={true}>
				<Input name="mobile" />
			</Form.Item>

			<Form.Item name="email" label="Email" required={true}>
				<Input name="email" />
			</Form.Item>

			<Form.Item name="agent_type" label="Tipo de Agente" required={true}>
				<Select
					name="agent_type"
					options={optionCascader}
					placeholder="Selecciona un Tipo"
				/>
			</Form.Item>

			<Form.Item name="rfc" label="RFC">
				<Select
					showSearch={true}
					name="rfc"
					placeholder="Selecciona un RFC"
					filterOption={(input, option) =>
						option.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}
					filterSort={(optionA, optionB) =>
						optionA.children
							.toLowerCase()
							.localeCompare(optionB.children.toLowerCase())
					}
				>
					{cascaderData.map((option) => (
						<Select.Option key={option.key} value={option.value}>
							{option.label}
						</Select.Option>
					))}
				</Select>
				<Button type="link" onClick={onNewRfc}>
					Agregar Nuevo RFC
				</Button>
			</Form.Item>

			<Button
				style={{marginInline: 5, marginLeft: 500}}
				onClick={() => props.onCancel()}
			>
				Cancelar
			</Button>
			<SubmitButton>Guardar</SubmitButton>
		</Form>
	)
}

export default AgentForm
