import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, message, Form} from 'antd'
import LayoutInternal from '../../utils/layout/LayoutInternal/LayoutInternal'
import {
	addPolicy,
	getPolicy,
	getDetailPolicy,
	deletePolicy,
	editPolicy,
} from '../../redux/actions/policyActions'
import PolicyTable from '../../components/tables/PolicyTable/policyTable'
import PolicyDrawer from '../../components/drawers/policyDrawer'

// Insurance Company actions
import {
	getInsuranceCompany,
	addInsuranceCompany,
} from '../../redux/actions/insuranceCompanyActions'
import InsuranceCompanyModal from '../../components/modals/insuranceCompanyModal'
// Type Insuranmce actions
import {
	getTypesInsurance,
	addTypeInsurance,
} from '../../redux/actions/typeInsuranceActions'
import TypeInsuranceModal from '../../components/modals/typeInsuranceModal'
// Client actions
import {getClients, addClients} from '../../redux/actions/clientsActions'
import ClientModal from '../../components/modals/ClientModals'
// Agent actions
import {getAgent, addAgent} from '../../redux/actions/agentActions'
import AgentModal from '../../components/modals/agentModal'
// RFC actions
import {getRfc, addRfc} from '../../redux/actions/rfcActions'
import RfcModal from '../../components/modals/rfcModal'
import moment from 'moment'

const PolicyContainer = () => {
	const [formAdd] = Form.useForm();
	const [formUpdate] = Form.useForm();
	
	const dispatch = useDispatch()

	const {
		isLoadingPolicyList,
		isLoadingAddPolicy,
		isLoadingUpdatePolicy,
		isLoadingFetchPolicy,
		showError,
		showSuccess,
	} = useSelector((state) => state.policyReducer)

	const itemsTotalList = useSelector(
		(state) => state.policyReducer.list.count
	)

	const listPolicy = useSelector((state) => state.policyReducer.list.results)

	const currentPage = useSelector((state) => state.policyReducer.currentPage)

	const detailRecord = useSelector((state) => state.policyReducer.detail)

	useEffect(() => {
		dispatch(getPolicy({page: 1}))
	}, [dispatch])

	const receiptsToEdit = React.useCallback( () => {
		const information = detailRecord.receipts.map((info) => {
			let receipt = {
				payment: info.payment,
				payment_scheduled_date: moment(info.payment_scheduled_date)
			}
			return receipt
		})

		return information
	},[detailRecord])

	const insuredsToEdit = React.useCallback( () => {
		const information = detailRecord.insureds.map((info) => {
			let insured = {
				name: info.contact.name,
				first_last_name: info.contact.first_last_name,
				second_last_name: info.contact.second_last_name,
				birthday: moment(info.birthday),
			}
			
			return insured
		})

		return information
	},[detailRecord])

 	useEffect(() => {
		if(!isLoadingFetchPolicy){
			const dates = [
				moment(detailRecord.start_date),
				moment(detailRecord.expires_date),
			]

			formUpdate.setFieldsValue({
				policy_number: detailRecord.policy_number,
				dates: dates,
				total_payment: detailRecord.total_payment,
				status: detailRecord.status,
				insurance_company: detailRecord.insurance_company,
				type_insurance: detailRecord.type_insurance,
				client: detailRecord.client,
				agent: detailRecord.agent,
				sub_agent: detailRecord.sub_agent,
				receipts: detailRecord.receipts ? receiptsToEdit(): undefined,
				insureds: detailRecord.insureds ? insuredsToEdit() : undefined,
			});
		}
	}, [formUpdate, isLoadingFetchPolicy,detailRecord , receiptsToEdit,insuredsToEdit]);

	useEffect(() => {
		if (!isLoadingUpdatePolicy) {
			handleCancelEditPolicy()
		}
	}, [isLoadingUpdatePolicy])

	useEffect(() => {
		if (showError) {
			message.error('Accion realizada incorrectamente')
		} else if (showSuccess) {
			message.success('Accion realizada correctamente')
		}
	}, [showError, showSuccess])

	const [isDrawerAddPolicyVisible, setIsDrawerAddPolicyVisible] =
		useState(false)

	const [isDrawerEditPolicyVisible, setIsDrawerEditPolicyVisible] =
		useState(false)

	const showDrawerAddPolicy = () => {
		setIsDrawerAddPolicyVisible(true)
	}

	const showDrawerEditPolicy = () => {
		setIsDrawerEditPolicyVisible(true)
	}

	const handleCancelAddPolicy = () => {
		setIsDrawerAddPolicyVisible(false)
	}

	const handleCancelEditPolicy = () => {
		setIsDrawerEditPolicyVisible(false)
	}

	const makeInsuredsList = (insureds) => {
		if (insureds !== undefined){
			const listInsureds = insureds.map((info) => {
				let insured = {
					birthday: info.birthday.format('YYYY-MM-DD'),
					contact: {
						name: info.name,
						first_last_name: info.first_last_name,
						second_last_name: info.second_last_name,
					}
				}
				return insured
			})

			return listInsureds
		}else {
			return undefined
		}
	}

	const makeReceiptsList = (receipts) => {
		if (receipts !== undefined) {
			let count = 1
			const receiptsList = receipts.map((info) => {
				let receipt = {
					payment: info.payment,
					payment_scheduled_date: info.payment_scheduled_date.format('YYYY-MM-DD'),
					payments_number: count
				}
				count ++
				return receipt
			})

			return receiptsList
		} else {
			return undefined
		}
	}

	const handleAddPolicySubmit = (values) => {
		
		const formatAddValues = {
			policy_number: values.policy_number,
			start_date: values.dates ? values.dates[0].format('YYYY-MM-DD') : undefined,
			expires_date: values.dates ? values.dates[1].format('YYYY-MM-DD') : undefined,
			total_payment: values.total_payment,
			status: values.status,
			insurance_company: values.insurance_company,
			type_insurance: values.type_insurance,
			client: values.client,
			agent: values.agent,
			sub_agent: values.sub_agent,
			receipts: makeReceiptsList(values.receipts),
			insureds: makeInsuredsList(values.insureds),
		}

		dispatch(addPolicy(formatAddValues))
		setIsDrawerAddPolicyVisible(false)
	}

	const handleEditPolicySubmit = (values) => {

		const formatEditValues = {
			id: detailRecord.id,
			user: detailRecord.user,
			policy_number: values.policy_number,
			start_date: values.dates ? values.dates[0].format('YYYY-MM-DD') : undefined,
			expires_date: values.dates ? values.dates[1].format('YYYY-MM-DD') : undefined,
			total_payment: values.total_payment,
			status: values.status,
			insurance_company: values.insurance_company,
			type_insurance: values.type_insurance,
			client: values.client,
			agent: values.agent,
			sub_agent: values.sub_agent,
			receipts: makeReceiptsList(values.receipts),
			insureds: makeInsuredsList(values.insureds),
		}

		dispatch(editPolicy(formatEditValues))
		setIsDrawerAddPolicyVisible(false)
	}

	const handleChangePagination = (numberPag) => {
		dispatch(getPolicy({page: numberPag}))
	}

	const handleDelete = (key) => {
		dispatch(deletePolicy(key))
	}

	const getDetail = (key) => {
		dispatch(getDetailPolicy(key))
		showDrawerEditPolicy()
	}

	// ---------- RFC Modal logic -------------

	const rfcList = useSelector((state) => state.rfcReducer.list.results)

	useEffect(() => {
		dispatch(getRfc({page: 1}))
	}, [dispatch])

	const [isModalRfcVisible, setIsModalRfcVisible] = useState(false)

	const onNewRfc = () => {
		setIsModalRfcVisible(true)
	}

	const handleCancelRfc = () => {
		setIsModalRfcVisible(false)
	}

	const handleRfcSubmit = (values, actions) => {
		const customData = {
			fiscal_id: values.fiscal_id,
			fiscal_name: values.fiscal_name,
			fiscal_type: values.fiscal_type[0],
		}
		dispatch(addRfc(customData))
		actions.resetForm()

		setIsModalRfcVisible(false)
	}

	// ----------  Insurance Company Modal logic -------------

	const insuranceCompanyList = useSelector(
		(state) => state.insuranceCompanyReducer.list.results
	)

	useEffect(() => {
		dispatch(getInsuranceCompany({page: 1}))
	}, [dispatch])

	const [isModalInsuranceCompanyVisible, setIsModalInsuranceCompanyVisible] =
		useState(false)

	const onNewInsuranceCompany = () => {
		setIsModalInsuranceCompanyVisible(true)
	}

	const handleCancelInsuranceCompany = () => {
		setIsModalInsuranceCompanyVisible(false)
	}

	const handleInsuranceCompanySubmit = (values, actions) => {
		const customData = {
			name: values.name,
			rfc: values.rfc[0],
		}

		dispatch(addInsuranceCompany(customData))
		actions.resetForm()

		setIsModalInsuranceCompanyVisible(false)
	}

	// ----------  Type Insurance Modal logic -------------

	const typeInsuranceList = useSelector(
		(state) => state.typeInsuranceReducer.list.results
	)

	useEffect(() => {
		dispatch(getTypesInsurance({page: 1}))
	}, [dispatch])

	const [isModalTypeInsuranceVisible, setIsModalTypeInsuranceVisible] =
		useState(false)

	const onNewTypeInsurance = () => {
		setIsModalTypeInsuranceVisible(true)
	}

	const handleCancelTypeInsurance = () => {
		setIsModalTypeInsuranceVisible(false)
	}

	const handleTypeInsuranceSubmit = (values, actions) => {
		dispatch(addTypeInsurance(values))
		actions.resetForm()

		setIsModalTypeInsuranceVisible(false)
	}

	// ----------  Client Modal logic -------------

	const clientList = useSelector((state) => state.clientsReducer.list.results)

	useEffect(() => {
		dispatch(getClients({page: 1}))
	}, [dispatch])

	const [isModalClientVisible, setIsModalClientVisible] = useState(false)

	const onNewClient = () => {
		setIsModalClientVisible(true)
	}

	const handleCancelClient = () => {
		setIsModalClientVisible(false)
	}

	const handleClientSubmit = (values, actions) => {
		const FormatAddValues = {
			contact:{
				name: values.name,
				first_last_name: values.first_last_name,
				second_last_name: values.second_last_name,
				email: values.email,
				mobile: values.mobile,
				phone_number: values.phone_number
			},
			marital_state: values.marital_state[0],
			gender: values.gender[0],
			rfc: values.rfc[0],
		}

		dispatch(addClients(FormatAddValues))
		actions.resetForm()
		setIsModalClientVisible(false)
	}

	// ----------  Agent Modal logic -------------

	const agentList = useSelector((state) => state.agentReducer.list.results)

	useEffect(() => {
		dispatch(getAgent({page: 1}))
	}, [dispatch])

	const [isModalAgentVisible, setIsModalAgentVisible] = useState(false)

	const onNewAgent = () => {
		setIsModalAgentVisible(true)
	}

	const handleCancelAgent = () => {
		setIsModalAgentVisible(false)
	}

	const handleAgentSubmit = (values, actions) => {
		const customData = {
			contact: {
				name: values.name,
				first_last_name: values.first_last_name,
				second_last_name: values.second_last_name,
				email: values.email,
				phone_number: values.phone_number,
				mobile: values.mobile,
			},
			rfc: values.rfc[0],
			agent_type: values.agent_type[0],
		}

		dispatch(addAgent(customData))
		actions.resetForm()

		setIsModalAgentVisible(false)
	}

	return (
		<LayoutInternal mainTitle="Pólizas">
			<Button
				type="primary"
				style={{marginBottom: 16}}
				onClick={showDrawerAddPolicy}
			>
				Agregar
			</Button>

			<PolicyTable
				loadingTable={isLoadingPolicyList}
				dataTable={listPolicy}
				totalItems={itemsTotalList}
				onPagination={handleChangePagination}
				onDelete={handleDelete}
				getDetail={getDetail}
				currentPage={currentPage}
			/>

			<PolicyDrawer
				policyForm={formAdd}
				drawerTitle={'Agregar póliza'}
				drawerVisible={isDrawerAddPolicyVisible}
				drawerOnClose={handleCancelAddPolicy}
				drawerStyle={{ paddingBottom: 80 }}
				drawerSpinning={isLoadingAddPolicy}
				drawerSubmit={handleAddPolicySubmit}
				insuranceCompanyList={insuranceCompanyList}
				onNewInsuranceCompany={onNewInsuranceCompany}
				typeInsuranceList={typeInsuranceList}
				onNewTypeInsurance={onNewTypeInsurance}
				clientList={clientList}
				onNewClient={onNewClient}
				agentList={agentList}
				onNewAgent={onNewAgent}
			/>

			<PolicyDrawer
				policyForm={formUpdate}
				drawerTitle={'Editar póliza'}
				drawerVisible={isDrawerEditPolicyVisible}
				drawerOnClose={handleCancelEditPolicy}
				drawerStyle={{ paddingBottom: 80 }}
				drawerSpinning={isLoadingUpdatePolicy || isLoadingFetchPolicy}
				drawerSubmit={handleEditPolicySubmit}
				insuranceCompanyList={insuranceCompanyList}
				onNewInsuranceCompany={onNewInsuranceCompany}
				typeInsuranceList={typeInsuranceList}
				onNewTypeInsurance={onNewTypeInsurance}
				clientList={clientList}
				onNewClient={onNewClient}
				agentList={agentList}
				onNewAgent={onNewAgent}
			/>

			<InsuranceCompanyModal
				modalTitle="Agregar compañia aseguradora"
				modalVisible={isModalInsuranceCompanyVisible}
				modalSpinning={isLoadingAddPolicy}
				modalCancel={handleCancelInsuranceCompany}
				modalSubmit={handleInsuranceCompanySubmit}
				modalFormikInitialValues={{name: ''}}
				rfcList={rfcList}
				onNewRfc={onNewRfc}
			/>

			<TypeInsuranceModal
				modalTitle="Agregar tipo de seguro"
				modalVisible={isModalTypeInsuranceVisible}
				modalSpinning={isLoadingAddPolicy}
				modalCancel={handleCancelTypeInsurance}
				modalSubmit={handleTypeInsuranceSubmit}
				modalFormikInitialValues={{name: ' '}}
			/>

			<ClientModal
				modalTitle="Agregar Cliente"
				modalVisible={isModalClientVisible}
				modalSpinning={isLoadingAddPolicy}
				modalCancel={handleCancelClient}
				modalSubmit={handleClientSubmit}
				modalFormikInitialValues={{	
					contact:{
						name: '',
						first_last_name: '',
						second_last_name: '',
						email: '',
						mobile: '',
						phone_number: ''
					},
					gender: '',
					marital_state: '',
					rfc: 0
				}}
                rfcList={rfcList}
			/>

			<AgentModal
				modalTitle="Agregar agente"
				modalVisible={isModalAgentVisible}
				modalSpinning={isLoadingAddPolicy}
				modalCancel={handleCancelAgent}
				modalSubmit={handleAgentSubmit}
				modalFormikInitialValues={{
					name: '',
					first_last_name: '',
					email: '',
					mobile: '',
				}}
				rfcList={rfcList}
				onNewRfc={onNewRfc}
			/>

			<RfcModal
				modalTitle="Agregar nuevo RFC"
				modalVisible={isModalRfcVisible}
				modalCancel={handleCancelRfc}
				modalSubmit={handleRfcSubmit}
				modalFormikInitialValues={{
					fiscal_id: '',
					fiscal_name: '',
					fiscal_type: '',
				}}
			/>
		</LayoutInternal>
	)
}

export default PolicyContainer
