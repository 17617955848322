import {
	GET_INSURANCE_COMPANY_REQUEST,
	GET_INSURANCE_COMPANY_SUCCESS,
	GET_INSURANCE_COMPANY_ERROR,
	GET_DETAIL_INSURANCE_COMPANY_REQUEST,
	GET_DETAIL_INSURANCE_COMPANY_SUCCESS,
	GET_DETAIL_INSURANCE_COMPANY_ERROR,
	ADD_INSURANCE_COMPANY_REQUEST,
	ADD_INSURANCE_COMPANY_SUCCESS,
	ADD_INSURANCE_COMPANY_ERROR,
	EDIT_INSURANCE_COMPANY_REQUEST,
	EDIT_INSURANCE_COMPANY_SUCCESS,
	EDIT_INSURANCE_COMPANY_ERROR,
	DELETE_INSURANCE_COMPANY_SUCCESS,
	DELETE_INSURANCE_COMPANY_REQUEST,
	DELETE_INSURANCE_COMPANY_ERROR,
} from '../actions/insuranceCompanyActions'

const initialState = {
	isLoadingInsuranceCompanyList: false,
	isLoadingAddInsuranceCompany: false,
	isLoadingUpdateInsuranceCompany: false,
	isLoadingFetchInsuranceCompany: false,
	showError: false,
	showSuccess: false,
	currentPage: 1,
	list: {
		count: 0,
		results: [],
	},
	detail: {
		id: 0,
		user: 0,
		name: '',
		rfc: 0,
	},
}

export const insuranceCompanyReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_INSURANCE_COMPANY_REQUEST:
			return {
				...state,
				isLoadingAddInsuranceCompany: true,
				showSuccess: false,
				showError: false,
			}
		case ADD_INSURANCE_COMPANY_SUCCESS:
			return {
				...state,
				isLoadingAddInsuranceCompany: false,
				showSuccess: true,
			}
		case ADD_INSURANCE_COMPANY_ERROR:
			return {
				...state,
				isLoadingAddInsuranceCompany: false,
				showError: true,
			}
		case EDIT_INSURANCE_COMPANY_REQUEST:
			return {
				...state,
				isLoadingUpdateInsuranceCompany: true,
				showSuccess: false,
				showError: false,
			}
		case EDIT_INSURANCE_COMPANY_SUCCESS:
			return {
				...state,
				isLoadingUpdateInsuranceCompany: false,
				showSuccess: true,
			}
		case EDIT_INSURANCE_COMPANY_ERROR:
			return {
				...state,
				isLoadingUpdateInsuranceCompany: false,
				showError: true,
			}
		case GET_INSURANCE_COMPANY_REQUEST:
			return {
				...state,
				isLoadingInsuranceCompanyList: true,
				showSuccess: false
			}
		case GET_INSURANCE_COMPANY_SUCCESS:
			return {
				...state,
				isLoadingInsuranceCompanyList: false,
				list: action.payload.list,
				currentPage: action.payload.currentPage,
				showSuccess: false
			}
		case GET_INSURANCE_COMPANY_ERROR:
			return {
				...state,
				isLoadingInsuranceCompanyList: false,
			}
		case GET_DETAIL_INSURANCE_COMPANY_REQUEST:
			return {
				...state,
				isLoadingFetchInsuranceCompany: true,
			}
		case GET_DETAIL_INSURANCE_COMPANY_SUCCESS:
			return {
				...state,
				detail: action.payload.detail,
				isLoadingFetchInsuranceCompany: false,
			}
		case GET_DETAIL_INSURANCE_COMPANY_ERROR:
			return {
				...state,
				isLoadingFetchInsuranceCompany: false,
			}
		case DELETE_INSURANCE_COMPANY_REQUEST:
			return {
				...state,
				isLoadingInsuranceCompanyList: true,
				showSuccess: false,
				showError: false,
			}
		case DELETE_INSURANCE_COMPANY_SUCCESS:
			return {
				...state,
				isLoadingInsuranceCompanyList: false,
				currentPage: action.payload.currentPage,
				showSuccess: true,
			}
		case DELETE_INSURANCE_COMPANY_ERROR:
			return {
				...state,
				isLoadingInsuranceCompanyList: false,
				showError: true,
			}
		default:
			return state
	}
}
