import * as Yup from 'yup'

const movileRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const movileRegExpLenght = /^[0-9]{10}$/

const AgentFormValidation = Yup.object().shape({
	name: Yup.string().required('Requerido'),
	first_last_name: Yup.string().required('Requerido'),
	email: Yup.string().email('Email inválido').required('Requerido'),
	mobile: Yup.string()
		.matches(movileRegExp, ' Número de celular invalido')
		.matches(movileRegExpLenght, 'Número de celular invalido')
		.required('Requerido'),
	agent_type: Yup.string().required('Requerido'),
})

export default AgentFormValidation
