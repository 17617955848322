import {takeLatest, call, put} from '@redux-saga/core/effects'
import {
	addPolicy,
	getPolicy,
	getDetailPolicy,
	deletePolicy,
	editPolicy,
} from '../../api/policyServices'
import {
	ADD_POLICY_REQUEST,
	ADD_POLICY_SUCCESS,
	ADD_POLICY_ERROR,
	GET_POLICY_REQUEST,
	GET_POLICY_SUCCESS,
	GET_POLICY_ERROR,
	GET_DETAIL_POLICY_REQUEST,
	GET_DETAIL_POLICY_SUCCESS,
	GET_DETAIL_POLICY_ERROR,
	DELETE_POLICY_REQUEST,
	DELETE_POLICY_SUCCESS,
	DELETE_POLICY_ERROR,
	EDIT_POLICY_REQUEST,
	EDIT_POLICY_SUCCESS,
	EDIT_POLICY_ERROR,
} from '../actions/policyActions'

export function* addPolicyWatcher() {
	yield takeLatest(ADD_POLICY_REQUEST, addPolicyFlow)
}

export function* addPolicyFlow(action) {
	try {
		yield call(addPolicy, action.payload)
		yield put({type: ADD_POLICY_SUCCESS, payload: {}})
	} catch (e) {
		yield put({type: ADD_POLICY_ERROR, payload: e})
	}
}

export function* addPolicySuccessWatcher() {
	yield takeLatest(ADD_POLICY_SUCCESS, paginationRefresh)
}

export function* getPolicyWatcher() {
	yield takeLatest(GET_POLICY_REQUEST, getPolicyFlow)
}

export function* getPolicyFlow(action) {
	try {
		const policyList = yield call(getPolicy, action.payload.page)
		yield put({
			type: GET_POLICY_SUCCESS,
			payload: {
				list: policyList,
				currentPage: action.payload.page,
			},
		})
	} catch (e) {
		yield put({type: GET_POLICY_ERROR, payload: e})
	}
}

export function* getDetailPolicyWatcher() {
	yield takeLatest(GET_DETAIL_POLICY_REQUEST, getDetailPolicyFlow)
}

export function* getDetailPolicyFlow(action) {
	try {
		const detailPolicy = yield call(getDetailPolicy, action.payload)
		yield put({
			type: GET_DETAIL_POLICY_SUCCESS,
			payload: {
				detail: detailPolicy,
			},
		})
	} catch (e) {
		yield put({
			type: GET_DETAIL_POLICY_ERROR,
			payload: e,
		})
	}
}

export function* deletePolicyWatcher() {
	yield takeLatest(DELETE_POLICY_REQUEST, deletePolicyFlow)
}

export function* deletePolicyFlow(action) {
	try {
		const policyDel = yield call(deletePolicy, action.payload)
		yield put({
			type: DELETE_POLICY_SUCCESS,
			payload: policyDel,
		})
	} catch (e) {
		yield put({type: DELETE_POLICY_ERROR, payload: e})
	}
}

export function* deletePolicySuccessWatcher() {
	yield takeLatest(DELETE_POLICY_SUCCESS, paginationRefresh)
}

export function* editPolicyWatcher() {
	yield takeLatest(EDIT_POLICY_REQUEST, editPolicyFlow)
}

export function* editPolicyFlow(action) {
	try {
		yield call(editPolicy, action.payload)
		yield put({
			type: EDIT_POLICY_SUCCESS,
			payload: {},
		})
	} catch (e) {
		yield put({type: EDIT_POLICY_ERROR, payload: e})
	}
}

export function* editPolicySuccessWatcher() {
	yield takeLatest(EDIT_POLICY_SUCCESS, paginationRefresh)
}

export function* paginationRefresh(action) {
	yield put({
		type: GET_POLICY_REQUEST,
		payload: {page: 1},
	})
}
