export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST'
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS'
export const GET_CLIENTS_ERROR = 'GET_CLIENTS_ERROR'

export const DELETE_CLIENTS_REQUEST = 'DELETE_CLIENTS_REQUEST'
export const DELETE_CLIENTS_SUCCESS = 'DELETE_CLIENTS_SUCCESS'
export const DELETE_CLIENTS_ERROR = 'DELETE_CLIENTS_ERROR'

export const GET_DETAIL_CLIENTS_REQUEST ='GET_DETAIL_CLIENTS_REQUEST' 
export const GET_DETAIL_CLIENTS_SUCCESS ='GET_DETAIL_CLIENTS_SUCCESS'
export const GET_DETAIL_CLIENTS_ERROR ='GET_DETAIL_CLIENTS_ERROR'

export const EDIT_CLIENTS_REQUEST = 'EDIT_CLIENTS_REQUEST'
export const EDIT_CLIENTS_SUCCESS = 'EDIT_CLIENTS_SUCCESS'
export const EDIT_CLIENTS_ERROR = 'EDIT_CLIENTS_ERROR'

export const ADD_CLIENTS_REQUEST = 'ADD_CLIENTS_REQUEST'
export const ADD_CLIENTS_SUCCESS = 'ADD_CLIENTS_SUCCESS'
export const ADD_CLIENTS_ERROR = 'ADD_CLIENTS_ERROR'

export const getClients = (data) => ({
	type: GET_CLIENTS_REQUEST,
	payload: data,
})

export const getDetailClients = data => ({
	type: GET_DETAIL_CLIENTS_REQUEST,
	payload: data
})

export const deleteClients = data => ({
	type: DELETE_CLIENTS_REQUEST,
	payload: data
})

export const editClients = data => ({
	type: EDIT_CLIENTS_REQUEST,
	payload: data
})

export const addClients = data => ({
	type: ADD_CLIENTS_REQUEST,
	payload: data
})