import {takeLatest, call, put} from 'redux-saga/effects'
import {
	addInsuranceCompany,
	getInsuranceCompany,
	getDetailInsuranceCompany,
	editInsuranceCompany,
	deleteInsuranceCompany,
} from '../../api/insuranceCompanyServices'
import {
	GET_INSURANCE_COMPANY_REQUEST,
	GET_INSURANCE_COMPANY_SUCCESS,
	GET_INSURANCE_COMPANY_ERROR,
	GET_DETAIL_INSURANCE_COMPANY_REQUEST,
	GET_DETAIL_INSURANCE_COMPANY_SUCCESS,
	GET_DETAIL_INSURANCE_COMPANY_ERROR,
	ADD_INSURANCE_COMPANY_REQUEST,
	ADD_INSURANCE_COMPANY_SUCCESS,
	ADD_INSURANCE_COMPANY_ERROR,
	EDIT_INSURANCE_COMPANY_REQUEST,
	EDIT_INSURANCE_COMPANY_SUCCESS,
	EDIT_INSURANCE_COMPANY_ERROR,
	DELETE_INSURANCE_COMPANY_SUCCESS,
	DELETE_INSURANCE_COMPANY_REQUEST,
	DELETE_INSURANCE_COMPANY_ERROR,
} from '../actions/insuranceCompanyActions'

export function* addInsuranceCompanyWatcher() {
	yield takeLatest(ADD_INSURANCE_COMPANY_REQUEST, addInsuranceCompanyFlow)
}

export function* addInsuranceCompanyFlow(action) {
	try {
		yield call(addInsuranceCompany, action.payload)
		yield put({type: ADD_INSURANCE_COMPANY_SUCCESS, payload: {}})
	} catch (e) {
		yield put({type: ADD_INSURANCE_COMPANY_ERROR, payload: e})
	}
}

export function* addInsuranceCompanySuccessWatcher() {
	yield takeLatest(ADD_INSURANCE_COMPANY_SUCCESS, papginationRefresh)
}

export function* getInsuranceCompanyWatcher() {
	yield takeLatest(GET_INSURANCE_COMPANY_REQUEST, getInsuranceCompanyFlow)
}

export function* getInsuranceCompanyFlow(action) {
	try {
		const insuranceCompanyList = yield call(
			getInsuranceCompany,
			action.payload.page
		)
		yield put({
			type: GET_INSURANCE_COMPANY_SUCCESS,
			payload: {
				list: insuranceCompanyList,
				currentPage: action.payload.page,
			},
		})
	} catch (e) {
		yield put({type: GET_INSURANCE_COMPANY_ERROR, payload: e})
	}
}

export function* getDetailInsuranceCompanyWatcher() {
	yield takeLatest(
		GET_DETAIL_INSURANCE_COMPANY_REQUEST,
		getDetailInsuranceCompanyFlow
	)
}

export function* getDetailInsuranceCompanyFlow(action) {
	try {
		const detailInsuranceCompany = yield call(
			getDetailInsuranceCompany,
			action.payload
		)
		yield put({
			type: GET_DETAIL_INSURANCE_COMPANY_SUCCESS,
			payload: {
				detail: detailInsuranceCompany,
			},
		})
	} catch (e) {
		yield put({type: GET_DETAIL_INSURANCE_COMPANY_ERROR, payload: e})
	}
}

export function* deleteInsuranceCompanyWatcher() {
	yield takeLatest(
		DELETE_INSURANCE_COMPANY_REQUEST,
		deleteInsuranceCompanyFlow
	)
}

export function* deleteInsuranceCompanyFlow(action) {
	try {
		const insuranceCompanyDel = yield call(
			deleteInsuranceCompany,
			action.payload
		)
		yield put({
			type: DELETE_INSURANCE_COMPANY_SUCCESS,
			payload: insuranceCompanyDel,
		})
	} catch (e) {
		yield put({type: DELETE_INSURANCE_COMPANY_ERROR, payload: e})
	}
}

export function* deleteInsuranceCompanySuccessWatcher() {
	yield takeLatest(DELETE_INSURANCE_COMPANY_SUCCESS, papginationRefresh)
}

export function* editInsuranceCompanyWatcher() {
	yield takeLatest(EDIT_INSURANCE_COMPANY_REQUEST, editInsuranceCompanyFlow)
}

export function* editInsuranceCompanyFlow(action) {
	try {
		yield call(editInsuranceCompany, action.payload)
		yield put({type: EDIT_INSURANCE_COMPANY_SUCCESS, payload: {}})
	} catch (e) {
		yield put({type: EDIT_INSURANCE_COMPANY_ERROR, payload: e})
	}
}

export function* editInsuranceCompanySuccessWatcher() {
	yield takeLatest(EDIT_INSURANCE_COMPANY_SUCCESS, papginationRefresh)
}

export function* papginationRefresh(action) {
	yield put({
		type: GET_INSURANCE_COMPANY_REQUEST,
		payload: {page: 1},
	})
}
