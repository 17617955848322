import { PersistGate } from 'redux-persist/integration/react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

import { persistor } from './redux/storeConfig/store'
import LoginContainer from './containers/login/LoginContainer'
import DashboardContainer from './containers/dashboard/DashboardContainer'
import TypeInsuranceContainer from './containers/typeInsurance/TypeInsuranceContainer'
import { ProtectedRoute } from './utils/protected-route/ProtectedRoute'
import ClientsContainer from './containers/clients/ClientsContainer'
import InsuranceCompanyContainer from './containers/insuranceCompany/InsuranceCompany'
import PolicyContainer from './containers/policy/Policy'
import AgentContainer from './containers/agents/AgentContainer'
import './App.less'

const App = () => (
	<PersistGate loading={null} persistor={persistor}>
		<Router>
			<Switch>
				<ProtectedRoute path="/type-insurance" component={TypeInsuranceContainer} />
				<ProtectedRoute path="/dashboard" component={DashboardContainer} />
				<ProtectedRoute path="/clients" component={ClientsContainer} />
				<ProtectedRoute path="/agent" component={AgentContainer} />
				<ProtectedRoute path="/insurance-company" component={InsuranceCompanyContainer} />
				<ProtectedRoute path="/Policy" component={PolicyContainer} />
				<Route path="/" component={LoginContainer} />
			</Switch>
		</Router>
	</PersistGate>
)


export default App;
