import {takeLatest, call, put} from 'redux-saga/effects'

import {
	getClients,
	getDetailClients, 
	deleteClients,
	editClients,
	addClients
} from '../../api/clientService'

import {
	GET_CLIENTS_REQUEST,
	GET_CLIENTS_SUCCESS,
	GET_CLIENTS_ERROR,

	GET_DETAIL_CLIENTS_REQUEST,
	GET_DETAIL_CLIENTS_SUCCESS,
	GET_DETAIL_CLIENTS_ERROR,

	DELETE_CLIENTS_REQUEST,
	DELETE_CLIENTS_SUCCESS,
	DELETE_CLIENTS_ERROR,

	EDIT_CLIENTS_REQUEST,
    EDIT_CLIENTS_SUCCESS,
    EDIT_CLIENTS_ERROR,

	ADD_CLIENTS_REQUEST,
	ADD_CLIENTS_SUCCESS,
	ADD_CLIENTS_ERROR
} from '../actions/clientsActions'

export function* addClientWatcher() {
	yield takeLatest(ADD_CLIENTS_REQUEST, addClientFlow)
}

export function* addClientFlow(action){
	try{
		yield call(addClients, action.payload)
		yield put({type: ADD_CLIENTS_SUCCESS, payload:{}})
	}catch(error){
		yield put({type: ADD_CLIENTS_ERROR, payload: error})
	}
}	

export function* addClientSuccessWatcher() {
	yield takeLatest(ADD_CLIENTS_SUCCESS, paginationRefresh)
}

export function* getClientsWatcher() {
	yield takeLatest(GET_CLIENTS_REQUEST, getClientsFlow)
}

export function* getClientsFlow(action) {
	try {
		const clients = yield call(getClients, action.payload.page)
		yield put({
			type: GET_CLIENTS_SUCCESS,
			payload: {
				list: clients,
				currentPage: action.payload.page,
			},
		})
	} catch (e) {
		yield put({type: GET_CLIENTS_ERROR, payload: e})
	}
}

export function* getDetailClientsWatcher() {
	yield takeLatest(GET_DETAIL_CLIENTS_REQUEST,getDetailsClientsFlow)
}

export function* getDetailsClientsFlow(action) {
	try {
		const detailClients = yield call(getDetailClients,action.payload)
		yield put({
			type: GET_DETAIL_CLIENTS_SUCCESS,
			payload:{
				detail: detailClients
			}})
	} catch (e) {
		yield put({ type:GET_DETAIL_CLIENTS_ERROR, payload: e })
	}
}

export function* deleteClientsWatcher(){
	yield takeLatest(DELETE_CLIENTS_REQUEST,deleteClientsFlow)
}

export function* deleteClientsFlow(action){
	try {
		const clients = yield call(deleteClients, action.payload)
		yield put({ type: DELETE_CLIENTS_SUCCESS, payload:clients })
	} catch (e) {
		yield put({ type: DELETE_CLIENTS_ERROR, payload: e})
	}
}

export function* deleteClientsSuccessWatcher(){
   yield takeLatest(DELETE_CLIENTS_SUCCESS,paginationRefresh)
}

export function* editClientsWatcher() {
	yield takeLatest(EDIT_CLIENTS_REQUEST, editClientsFlow)
}

export function* editClientsFlow(action) {
	try {
		yield call(editClients, action.payload)
		yield put({ type: EDIT_CLIENTS_SUCCESS, payload: {} })
	} catch (e) {
		yield put({ type: EDIT_CLIENTS_ERROR, payload: e })
	}
}

export function* editClientsSuccessWatcher() {
	yield takeLatest(EDIT_CLIENTS_SUCCESS, paginationRefresh)
}

export function* paginationRefresh(action){
	yield put({
		type: GET_CLIENTS_REQUEST,
		payload:{ page: 1}
	})
}