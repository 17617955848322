import React from 'react'
import LayoutInternal from '../../utils/layout/LayoutInternal/LayoutInternal'
import EditableTable from '../../components/tables/ClientsTable/getClientsTable'
import {getClients,getDetailClients, deleteClients, editClients ,addClients} from '../../redux/actions/clientsActions'
import { getRfc } from '../../redux/actions/rfcActions'
import { useDispatch, useSelector } from 'react-redux'
import {Button,message} from 'antd'
import ClientModal from '../../components/modals/ClientModals'
import RfcModal from '../../components/modals/rfcModal'
import { addRfc } from '../../redux/actions/rfcActions'


const ClientsContainer = () => { 
    const dispatch = useDispatch()
 
	const clientsList = useSelector(
		(state) => state.clientsReducer.list.results
	)

	const {
		isLoadingClientsList,
		isLoadingAddClient,
		isLoadingFetchClients,
		isLoadingUpdateClients,
		showError,
		errorMessage,
		showSuccess
	} = useSelector((state) => state.clientsReducer)

	const itemsTotalList = useSelector(
		(state) => state.clientsReducer.list.count
	)

	const rfcList = useSelector( state => state.rfcReducer.list.results)

	const currentPage = useSelector(
		(state) => state.clientsReducer.currentPage
	)
    
	const detailRecord = useSelector(
		(state) => state.clientsReducer.detail
	)
	React.useEffect(() => {
		dispatch(getClients({page:1}))
	},[dispatch])

	//* getting rfc list
    React.useEffect (() => { dispatch(getRfc({page:1})) },[dispatch])

	React.useEffect(()=>{
		if(showError){
			message.error('Accion realizada incorrectamente')
		} else if (showSuccess){
			message.success('Accion realizada correctamente')
		}
	},[showError,showSuccess,errorMessage])

	React.useEffect(()=>{
		if(!isLoadingUpdateClients){
			handleCancelEditClients()
		}
	},[isLoadingUpdateClients])

	const handleChangePagination = (numberPag) => {
		dispatch(getClients({page: numberPag}))
	}

	const handleDelete = (key) => {
		dispatch(deleteClients(key))
	}
    
	const [
		isModalEditClientsVisible,
		setIsEditModalClientsVisible
	] = React.useState(false)

	const [
		isModalAddClientVisible,
		setIsModalAddClientVisible
	] = React.useState(false)

	const showAddClient = () => {
		setIsModalAddClientVisible(true)
	}	

	const handleCancelAddClient = () => {
		setIsModalAddClientVisible(false)
	}

	const showModalEditClients = () => {
		setIsEditModalClientsVisible(true)
	}

	const handleCancelEditClients = () => {
		setIsEditModalClientsVisible(false)
	}

	const handleClientsSubmit = (values, actions) => {
		const {id,marital_state,gender,rfc,...rest} = values
		const FormatAddValues = {
			contact:{
				...rest
			},
			marital_state,
			gender,
			rfc
		}
		console.log(FormatAddValues)

		const FormatEditValues = {
			id,
			contact:{
				...rest
			},
			marital_state,
			gender,
			rfc: (rfc=== undefined)?null: rfc
		}
		
		values.hasOwnProperty('id')
			?dispatch(editClients(FormatEditValues))
			:dispatch(addClients(FormatAddValues))
		
		actions.resetForm()
		setIsModalAddClientVisible(false)
	}

	const getDetail = (key) => {
		dispatch(getDetailClients(key))
		showModalEditClients()
	}

	//* ---------------RFC Modal Logic-----------------

	const [isModalRfcVisible, setIsModalRfcVisible] = React.useState(false)


	const onNewRfc = () => {
		setIsModalRfcVisible(true)
	}

	const handleCancelRfc = () => {
		setIsModalRfcVisible(false)
	}
  
	const handleRfcSubmit = (values, actions) => {
		dispatch(addRfc({...values}))
		actions.resetForm()
		setIsModalRfcVisible(false)
	}

	return(
		<LayoutInternal mainTitle="Clientes">
			<Button type="primary" onClick={showAddClient} >
              Crear Cliente
			</Button>
			<EditableTable 
				loadingClients={isLoadingClientsList}
				data={clientsList}
				totalItems={itemsTotalList}
				currentPage={currentPage}
				onDelete = {handleDelete}
				getDetail={getDetail}
				onPagination={handleChangePagination}
			/>
			<ClientModal
				modalTitle="Agregar Cliente"
				modalVisible={isModalAddClientVisible}
				modalSpinning={isLoadingAddClient}
				modalCancel={handleCancelAddClient}
				modalSubmit={handleClientsSubmit}
				modalFormikInitialValues={{	
					name: '',
					first_last_name: '',
					second_last_name: '',
					email: '',
					mobile: '',
					phone_number: '',
					gender: '',
					marital_state: '',
					rfc: ''
				}}
                rfcList={rfcList}
				onNewRfc={onNewRfc}
			/>

			<ClientModal
			    modalTitle="Editar Información"
				modalVisible={isModalEditClientsVisible}
				modalCancel={handleCancelEditClients}
				modalSpinning = {
					isLoadingFetchClients || isLoadingUpdateClients
				}
				modalSubmit={handleClientsSubmit}
				modalFormikInitialValues = {{
					id: detailRecord.id,
					name: detailRecord.contact.name,
					first_last_name: detailRecord.contact.first_last_name,
					second_last_name: detailRecord.contact.second_last_name,
					email: detailRecord.contact.email,
					mobile: detailRecord.contact.mobile,
					phone_number: detailRecord.contact.phone_number,
					gender: detailRecord.gender,
					marital_state: (detailRecord.marital_state === '')?'':detailRecord.marital_state,
					//rfc:(detailRecord.rfc === null)?'':detailRecord.rfc
				}}
				rfcList={rfcList}
				onNewRfc={onNewRfc}
			/>

			<RfcModal
				modalTitle="Agregar nuevo RFC"
				modalVisible={isModalRfcVisible}
				modalCancel={handleCancelRfc}
				modalSubmit={handleRfcSubmit}
				modalFormikInitialValues={{
					fiscal_id: '',
					fiscal_name: '',
					fiscal_type: '',
				}}
			/>
		</LayoutInternal>
	)
}


export default ClientsContainer
