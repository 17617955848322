import {axiosService} from './requestService'
import {authHeader} from '../utils/core/functions'

export const addInsuranceCompany = async (data) => {
	return axiosService
		.post(`/api/insurancecompany/`, (data = {...data}), {
			headers: authHeader(),
		})
		.then((res) => res.data)
}

export const getInsuranceCompany = async (data) => {
	return axiosService
		.get(`/api/insurancecompanylist`, {
			headers: authHeader(),
			params: {
				page: data,
			},
		})
		.then((res) => res.data)
}

export const deleteInsuranceCompany = async (data) => {
	return axiosService
		.delete(`/api/insurancecompany/${data}`, {
			headers: authHeader(),
		})
		.then((res) => res.data)
}

export const editInsuranceCompany = async (data) => {
	return axiosService
		.put(`/api/insurancecompany/${data.id}/`, (data = {...data}), {
			headers: authHeader(),
		})
		.then((res) => res.data)
}

export const getDetailInsuranceCompany = async (data) => {
	return axiosService
		.get(`/api/insurancecompany/${data}`, {
			headers: authHeader(),
		})
		.then((res) => res.data)
}
