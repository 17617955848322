export const ADD_AGENT_REQUEST = 'ADD_AGENT_REQUEST'
export const ADD_AGENT_SUCCESS = 'ADD_AGENT_SUCCESS'
export const ADD_AGENT_ERROR = 'ADD_AGENT_ERROR'

export const GET_AGENT_REQUEST = 'GET_AGENT_REQUEST'
export const GET_AGENT_SUCCESS = 'GET_AGENT_SUCCESS'
export const GET_AGENT_ERROR = 'GET_AGENT_ERROR'

export const GET_DETAIL_AGENT_REQUEST = 'GET_DETAIL_AGENT_REQUEST'
export const GET_DETAIL_AGENT_SUCCESS = 'GET_DETAIL_AGENT_SUCCESS'
export const GET_DETAIL_AGENT_ERROR = 'GET_DETAIL_AGENT_ERROR'

export const DELETE_AGENT_REQUEST = 'DELETE_AGENT_REQUEST'
export const DELETE_AGENT_SUCCESS = 'DELETE_AGENT_SUCCESS'
export const DELETE_AGENT_ERROR = 'DELETE_AGENT_ERROR'

export const EDIT_AGENT_REQUEST = 'EDIT_AGENT_REQUEST'
export const EDIT_AGENT_SUCCESS = 'EDIT_AGENT_SUCCESS'
export const EDIT_AGENT_ERROR = 'EDIT_AGENT_ERROR'

export const addAgent = (data) => ({
	type: ADD_AGENT_REQUEST,
	payload: data,
})

export const getAgent = (data) => ({
	type: GET_AGENT_REQUEST,
	payload: data,
})

export const getDetailAgent = (data) => ({
	type: GET_DETAIL_AGENT_REQUEST,
	payload: data,
})

export const deleteAgent = (data) => ({
	type: DELETE_AGENT_REQUEST,
	payload: data,
})

export const editAgent = (data) => ({
	type: EDIT_AGENT_REQUEST,
	payload: data,
})
