import React from 'react'
import {Form, Input, SubmitButton, Select} from 'formik-antd'
import {Button} from 'antd'

const optionsCascader = [
	{
		value: 'COMPANY',
		label: 'Moral',
	},
	{
		value: 'PERSON',
		label: 'Física',
	},
]

const RfcForm = (props) => {
	return (
		<Form preserve={false}layout="vertical">
			<Form.Item
				name="fiscal_id"
				label="RFC"
				required={true}
			>
				<Input name="fiscal_id" />
			</Form.Item>
			<Form.Item name="fiscal_name" label="Razon social" required={true}>
				<Input name="fiscal_name" />
			</Form.Item>
			<Form.Item name="fiscal_type" label="Tipo de persona" required={true}>
				<Select name="fiscal_type" options={optionsCascader} placeholder=" Fiscal "/>
			</Form.Item>
			<Button
				style={{marginInline: 5, marginLeft: 270}}
				onClick={() => props.onCancel()}
			>
				Cancelar
			</Button>
			<SubmitButton>Guardar</SubmitButton>
		</Form>
	)
}

export default RfcForm
